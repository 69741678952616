import { motion, AnimatePresence, useAnimationControls } from "framer-motion"
import { useEffect, useState } from "react"
import Footer from "../00-Sitewide/footer"


export function DesignSystemTokens() {
    return <div className="display-tables monospace">

        {/* COLOR TOKEN CARD */}
        <div className="table-card">
            <div className="title-section">
                <div className="card-title">Color Tokens</div>
                <div className="card-subtitle">//hover an element to see its usage</div>
            </div>
            <div className="table">
                <div className="table-title-section">
                    <div className="table-title">Text</div>
                    <div className="table-subtitle">//just an example of a token set</div>
                </div>
                <TableRow
                    name="$text-default"
                    hexcode="#121213"
                    altName="Black"
                    usage="Primary text, such as body copy"
                >
                    <div className="color-block" style={{backgroundColor:"#121213"}}/>
                </TableRow>
                <TableRow
                    name="$text-subtle"
                    hexcode="#333336"
                    altName="Gray 80"
                    usage="Helpers, subtitles, secondary lines"
                >
                    <div className="color-block" style={{backgroundColor:"#333336"}}/>
                </TableRow>
                <TableRow
                    name="$text-subtler"
                    hexcode="#75757C"
                    altName="Gray 70"
                    usage="Navigation, input labels, subheadings"
                >
                    <div className="color-block" style={{backgroundColor:"#75757C"}}/>
                </TableRow>
                <TableRow
                    name="$text-subtlest"
                    hexcode="#98989D"
                    altName="Gray 60"
                    usage="Breadcrumbs, input field placeholder"
                >
                    <div className="color-block" style={{backgroundColor:"#98989D"}}/>
                </TableRow>
                <TableRow
                    name="$text-disabled"
                    hexcode="#A9A9AE"
                    altName="Gray 50"
                    usage="Only used in disabled state"
                >
                    <div className="color-block" style={{backgroundColor:"#A9A9AE"}}/>
                </TableRow>
                <TableRow
                    name="$text-inverse"
                    hexcode="#FFFFFF"
                    altName="White"
                    usage="Accessible on bold & dark backgrounds"
                >
                    <div className="color-block" style={{backgroundColor:"#FFFFFF"}}/>
                </TableRow>
                <TableRow
                    name="$text-interactive"
                    hexcode="#1D62B1"
                    altName="Blue 80"
                    usage="Interactive elements like links"
                >
                    <div className="color-block" style={{backgroundColor:"#1D62B1"}}/>
                </TableRow>
                <TableRow
                    name="$text-warning"
                    hexcode="#C87A00"
                    altName="Gold 80"
                    usage="Meant to exercise caution"
                >
                    <div className="color-block" style={{backgroundColor:"#C87A00"}}/>
                </TableRow>
                <TableRow
                    name="$text-success"
                    hexcode="#407D32"
                    altName="Green 80"
                    usage="Used for confirmation & positive outcomes"
                >
                    <div className="color-block" style={{backgroundColor:"#407D32"}}/>
                </TableRow>
                <TableRow
                    name="$text-danger"
                    hexcode="#970C11"
                    altName="Red 90"
                    usage="Error message text only"
                >
                    <div className="color-block" style={{backgroundColor:"#970C11"}}/>
                </TableRow>
            </div>
        </div>

        {/* TYPOGRAPHY TABLE CARD */}
        <div className="table-card">
            <div className="title-section">
                <div className="card-title">Typography Styles</div>
                <div className="card-subtitle">//Using Lato 400/700/900</div>
            </div>
            <div className="table">
                <div className="table-title-section">
                    <div className="table-title">Headings</div>
                    <div className="table-subtitle">//additional tokens for buttons, eyebrows, etc</div>
                </div>
                <TableRow
                    name="$font-size-40"
                    hexcode=""
                    altName="40px/56px (2.5rem)"
                    usage="Top of page titles only"
                >
                    <div className="font-block" style={{fontSize: '40px'}}>head1</div>
                </TableRow>
                <TableRow
                    name="$font-size-36"
                    hexcode=""
                    altName="36px/44px (2.25rem)"
                    usage="Main section & modal titles"
                >
                    <div className="font-block" style={{fontSize: '36px'}}>head2</div>
                </TableRow>
                <TableRow
                    name="$font-size-32"
                    hexcode=""
                    altName="32px/44px (2rem)"
                    usage="Product name & price (large)"
                >
                    <div className="font-block" style={{fontSize: '32px'}}>head3</div>
                </TableRow>
                <TableRow
                    name="$font-size-28"
                    hexcode=""
                    altName="28px/32px (1.75rem)"
                    usage="Secondary page header"
                >
                    <div className="font-block" style={{fontSize: '28px'}}>head4</div>
                </TableRow>
                <TableRow
                    name="$font-size-24"
                    hexcode=""
                    altName="24px/32px (1.5rem)"
                    usage="Subsection header"
                >
                    <div className="font-block" style={{fontSize: '24px'}}>head5</div>
                </TableRow>
                <TableRow
                    name="$font-size-20"
                    hexcode=""
                    altName="20px/28px (1.25rem)"
                    usage="Small element header"
                >
                    <div className="font-block" style={{fontSize: '20px'}}>head6</div>
                </TableRow>
                <TableRow
                    name="$font-size-18"
                    hexcode=""
                    altName="18px/24px (1.125rem)"
                    usage="Paragraph section subheads"
                >
                    <div className="font-block" style={{fontSize: '18px'}}>(p)-large</div>
                </TableRow>
                <TableRow
                    name="$font-size-16"
                    hexcode=""
                    altName="16px/24px (1rem)"
                    usage="Default for paragraph text"
                >
                    <div className="font-block" style={{fontSize: '16px'}}>(p)aragraph</div>
                </TableRow>
                <TableRow
                    name="$font-size-14"
                    hexcode=""
                    altName="14px/20px (.875rem)"
                    usage="Subtext for paragraphs, addendums"
                >
                    <div className="font-block" style={{fontSize: '14px'}}>(p)-small</div>
                </TableRow>
                <TableRow
                    name="$font-size-12"
                    hexcode=""
                    altName="12px/16px (.75rem)"
                    usage="Use sparingly, black only"
                >
                    <div className="font-block" style={{fontSize: '12px'}}>(p)-xsmall</div>
                </TableRow>
            </div>
        </div>
    </div>
}

export function DesignSystemMolecules() {
    return <div className="display-tables monospace">

        {/* TEXT INPUTS CARD */}
        <div className="table-card">
            <div className="title-section">
                <div className="card-title">Text Inputs</div>
                <div className="card-subtitle">//Separate from dropdowns, search, etc.</div>
            </div>
            <div className="table" style={{display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center'}}>
                <img src="01-discounttire/02-text-input/Text inputs - Default.png"/>
                <img src="01-discounttire/02-text-input/Text inputs - Default-1.png"/>
                <img src="01-discounttire/02-text-input/Text inputs - Default-2.png"/>
                <img src="01-discounttire/02-text-input/Text inputs - Default-3.png"/>
                <img src="01-discounttire/02-text-input/Text inputs - Default-4.png"/>
                <img src="01-discounttire/02-text-input/Text inputs - Default-5.png"/>
                <img src="01-discounttire/02-text-input/Text inputs - Default-6.png"/>
                <img src="01-discounttire/02-text-input/Text inputs - Default-7.png"/>
                <img src="01-discounttire/02-text-input/Text inputs - Default-8.png"/>
            </div>
        </div>

        {/* BUTTONS CARD */}
        <div className="table-card">
            <div className="title-section">
                <div className="card-title">Buttons</div>
                <div className="card-subtitle">//Minimum Width: 280px, Default Width: 343px</div>
            </div>
            <div className="table" style={{display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center'}}>
                <div className="table-button" style={{backgroundColor: '#E6121A'}}>Primary Default</div>
                <div className="table-button" style={{backgroundColor: '#B70E15'}}>Primary Pressed</div>
                <div className="table-button" style={{backgroundColor: '#F8AAAD'}}>Primary Disabled</div>
                <div className="table-button" style={{backgroundColor: '#E6121A'}}>Primary Loading</div>
                <div className="table-button" style={{border: '2px solid #E6121A', color: '#E6121A'}}>Secondary Default</div>
                <div className="table-button" style={{border: '2px solid #B70E15', color: '#B70E15'}}>Secondary Pressed</div>
                <div className="table-button" style={{border: '2px solid #A9A9AE', color: '#A9A9AE'}}>Secondary Disabled</div>
                <div className="table-button" style={{border: '2px solid #E6121A', color: '#E6121A'}}>Secondary Loading</div>
                <div className="table-button" style={{color: '#A9A9AE', letterSpacing: '3px'}}>+ Ghost Button</div>
            </div>
        </div>
    </div>
}

export function DesignSystemOrganisms() {
    return <div className="display-tables monospace">

        {/* COLOR TOKEN CARD */}
        <div className="table-card">
            <div className="title-section">
                <div className="card-title">Headers</div>
                <div className="card-subtitle">//Just an example of an organism I worked on</div>
                <div className="header-images">
                    {/* <img src="01-discounttire/03-headers/global-header-0.jpg"/> */}
                    <img src="01-discounttire/03-headers/global-header-1.jpg"/>
                    <img src="01-discounttire/03-headers/global-header-2.jpg"/>
                    {/* <img src="01-discounttire/03-headers/global-header-3.jpg"/> */}
                    <img src="01-discounttire/03-headers/global-header-4.jpg"/>
                    <img src="01-discounttire/03-headers/global-header-5.jpg"/>
                    <img src="01-discounttire/03-headers/global-header-6.jpg"/>
                    {/* <img src="01-discounttire/03-headers/global-header-7.jpg"/> */}
                </div>
            </div>
        </div>
    </div>
}

export function ProductListingPage(props) {
    const controls = useAnimationControls()

    useEffect(() => {
        controls.start(props.state + '')
    }, [props.state])

    return <div className="product-page-and-callouts">
        <motion.div 
          className="phone-column-titles" 
          style={{display:'flex', width:'70%'}}
          variants={{
            before: {x:'45%'},
            after: {x: '0%'}
          }}
          animate={props.state < 6 ? "before" : "after"}
        >
            <div className="monospace" style={{width:'50%', textAlign:'center', fontSize: '20px', marginBottom: '.5rem'}}>Before</div>
            <div className="monospace" style={{width:'50%', textAlign:'center', fontSize: '20px', marginBottom: '.5rem'}}>After</div>
        </motion.div>
        <div className="product-page">
            <PLPCallOuts
                state = {props.state}
            />
            <CallOutCard
                state = {props.state}
                variants = {plpCallOutCardVariants}
                cardContent = {plpCardContent}
            />
            
            <motion.div 
            className="two-phones" 
            variants={{
                before: {x:'45%'},
                after: {x: '0%'}
            }}
            animate={props.state < 6 ? "before" : "after"}
            >
                <div className="phone-device">
                    <img src="01-discounttire/04-plp/00-phoneframe.png"/>
                    <div className="plp-ui-holder">
                        <motion.img
                            className="plp-ui" 
                            src="01-discounttire/04-plp/01-plp-before.jpg"
                            variants={PLPScrollPositions}
                            animate={controls}
                        />
                    </div>
                </div>
                <div className="phone-device">
                    <img src="01-discounttire/04-plp/00-phoneframe.png"/>
                    <div className="plp-ui-holder">
                        <motion.img
                            className="plp-ui" 
                            src="01-discounttire/04-plp/02-plp-after.jpg"
                            variants={PLPScrollPositions}
                            animate={controls}
                        />
                    </div>
                </div>
            </motion.div>
        </div>
    </div>
}

export function ShoppingCart(props) {
    const controls = useAnimationControls()

    useEffect(() => {
        controls.start(props.state + '')
    }, [props.state])

    return <div className="desktop-holder">
        <div className="desktop-screen">
            <CartCallOuts
                state = {props.state}
            />
            <CallOutCard
                state = {props.state}
                variants = {cartCallOutCardVariants}
                cardContent = {cartCardContent}
            />
            <div style={{width: '100%', display: 'flex'}}>
                {props.state < 6 &&<motion.img 
                    style={{width: '70%'}}
                    src="01-discounttire/03-shopping-cart.jpg"
                    variants={cartScrollPositions}
                    animate={controls}
                />}
                {props.state > 5 &&<motion.img 
                    style={{width: '70%'}}
                    src="01-discounttire/03-shopping-cart-2.jpg"
                    variants={cartScrollPositions}
                    animate={controls}
                />}
            </div>
        </div>
    </div>
}

export function DetailedDesign() {
    return <h1>Detailed Design</h1>
}

export function Outcome() {
    return <div className="outcome-section">
        <div className="display-tables monospace">

            <div className="table-card">
                <div className="title-section">
                    <div className="card-title">Revenue Increase</div>
                    <div className="card-subtitle">//First 10 months</div>
                    <div className="outcome-number">
                        <div className="title">$51.2 million</div>
                        <div className="subtitle">annual increase<span className="superscript">*</span></div>
                        <div className="progress-circle"></div>
                    </div>
                </div>
            </div>
            <div className="table-card">
                <div className="title-section">
                    <div className="card-title">Further Increase</div>
                    <div className="card-subtitle">//Following 12 months</div>
                    <div className="outcome-number">
                        <div className="title">$50.4 million</div>
                        <div className="subtitle">additional annual increase<span className="superscript">*</span></div>
                        <div className="progress-circle"></div>
                    </div>
                </div>
            </div>
            <div className="table-card">
                <div className="title-section">
                    <div className="card-title">Conversion Increase</div>
                    <div className="card-subtitle">//Cart page redesign only</div>
                    <div className="outcome-number">
                    <div className="progress-circle"></div>
                        <div className="title">13% increase</div>
                        <div className="subtitle">in converting users who click on cart to customers<span className="superscript">*</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="monospace" style={{color:'#aaa', marginTop: '8px'}}>*Number derived from client's extensive A/B Testing framework, collected over a 4 week period</div>
        <Footer nextProject={2}/>
    </div>
}

const animationProps = {
    initial:{opacity: 0, y: 16},
    animate:{opacity: 1, y: 0},
    exit:{opacity: 0, y: -8},
    transition:{duration: '.25'}
}

const plpCardContent = [
    {title: 'Unintentional Spacing', body: 'With how much content is at the top of the page, no tire results even appear above the fold on mobile'},
    {title: 'Floating Alignment', body: 'With so many baselines for text, your eye gets tired trying to find the information you’re looking for'},
    {title: 'Poor Hierachy', body: 'Similar information isn’t grouped together, leading to confusion around fitment and product info'},
    {title: 'Inconsistent Imagery', body: 'Not every tire has thing ‘angle view’ that is 3:5 resolution, leading to odd results and consistency across this page'},
    {title: 'Lack of Spacing', body: 'Dense information causes more stress without being more informative'},
    {title: 'Too Many Styles', body: 'Too many text styles and colors cheapens the design and overwhelms the user'},
    {title: 'Two Flows in One', body: 'This tab design makes it easier to toggle between tires recommended by Discount Tire’s recommendation engine and viewing all products'},
    {title: 'Easier Customization', body: 'In the existing design, users had to go back to edit this information. This allows them to surface a drawer without leaving the shopping flow.'},
    {title: 'Treadwell First', body: 'Because they have business goals around increasing understanding and conversion of Treadwell it makes sense for it to be closer to the product image'},
    {title: 'Reorganized Hierarchy', body: 'It made sense to group information into logical sections, and then improve the baseline so that info isn’t floating'},
    {title: 'Visualizing Product Info', body: 'Grouping the stopping distance and mile estimate together allows Discount Tire’s Treadwell tire guide to become its own independent feature on the PLP'},
]

const PLPScrollPositions={
    0: { y: '0%' },
    1: { y: '-2%' },
    2: { y: '-10%' },
    3: { y: '-28%' },
    4: { y: '-30%' },
    5: { y: '-38%' },
    6: { y: '0%' },
    7: { y: '-5%' },
    8: { y: '-20%' },
    9: { y: '-30%' },
    10: { y: '-50%' },
}

function PLPCallOuts(props) {
    return <div className="call-out-area">
        <AnimatePresence>
            {props.state == 0 && <motion.div key={0} {...animationProps} className="call-out-section" style={{width: '47%', height: '66%', left: '46%', top: '16%'}}/>}
            {props.state == 1 && <motion.div key={1} {...animationProps} className="call-out-section" style={{width: '20%', height: '44%', left: '46%', top: '13%'}}/>}
            {props.state == 2 && <motion.div key={2} {...animationProps} className="call-out-section" style={{width: '25%', height: '4.5%', left: '57%', top: '19%'}}/>}
            {props.state == 2 && <motion.div key={3} {...animationProps} className="call-out-section" style={{width: '27%', height: '27%', left: '61%', top: '72%'}}/>}
            {props.state == 3 && <motion.div key={4} {...animationProps} className="call-out-section" style={{width: '17%', height: '28%', left: '45%', top: '30%'}}/>}
            {props.state == 4 && <motion.div key={5} {...animationProps} className="call-out-section" style={{width: '30%', height: '28%', left: '61%', top: '30%'}}/>}
            {/* {props.state == 5 && <motion.div key={6} {...animationProps} className="call-out-section" style={{width: '20%', height: '40%', left: '2%', top: '22%'}}/>} */}
            {props.state == 6 && <motion.div key={7} {...animationProps} className="call-out-section" style={{width: '47%', height: '7%', left: '51%', top: '18%'}}/>}
            {props.state == 7 && <motion.div key={8} {...animationProps} className="call-out-section" style={{width: '45%', height: '10%', left: '52%', top: '34.5%'}}/>}
            {props.state == 8 && <motion.div key={9} {...animationProps} className="call-out-section" style={{width: '25%', height: '6%', left: '53%', top: '22%'}}/>}
            {props.state == 9 && <motion.div key={10} {...animationProps} className="call-out-section" style={{width: '39%', height: '17%', left: '55%', top: '36%'}}/>}
            {props.state == 10 && <motion.div key={11} {...animationProps} className="call-out-section" style={{width: '39%', height: '21%', left: '55%', top: '33%'}}/>}
        </AnimatePresence>
    </div>
}

const plpCallOutCardVariants = {
    0: { left: '0%', top: '20%' },
    1: { left: '0%', top: '16%' },
    2: { left: '0%', top: '22%' },
    3: { left: '0%', top: '30%' },
    4: { left: '0%', top: '30%' },
    5: { left: '0%', top: '14%' },
    6: { left: '71%', top: '20%' },
    7: { left: '71%', top: '37%' },
    8: { left: '45%', top: '32%' },
    9: { left: '71%', top: '40%' },
    10: { left: '71%', top: '37%' },
}

const cartCardContent = [
    {title: 'Floating Alignment', body: 'There aren’t clearly defined columns in this design, so information gets cluttered and lost easily'},
    {title: 'Numbers Overload', body: 'With so many quantities and prices, customers weren’t sure which ones were important and how they were adding up'},
    {title: 'Multiple Subtotals', body: 'With several subtotal lines throughout the cart page, customers tried using calculators to understand their true total'},
    {title: 'Add-On Ambiguity', body: 'Certificates, which serve as a warranty on your tire, are not clearly called out and poorly communicated to the user'},
    {title: 'Poor Hierarchy', body: 'A major goal of the business is to increase accounts created, but the savings incentive they offer gets lost in the overall page'},
    {title: 'Empty Space & Scrolling', body: 'Lots of unintentional white space and poor component placement makes the user scroll a long way, especially on mobile'},
    {title: 'More Scannable', body: 'My primary goal was to make the cart easier to scan. To do this, I used a 12 column grid to divide the page into a cart summary and order summary'},
    {title: 'Progressive Disclosure', body: 'Because of the amount of copy and information that needed to be retained, I used dropdown arrows and ‘view details’ links to compress info'},
    {title: 'Certificates Explained', body: 'I created a unique treatment for upsell and add-on components on products giving increased visibility to warranties and add-ons'},
    {title: 'Components First', body: 'Features like this floating “other ways to pay” box could be re-used on other pages like checkout, so I built components with the whole site in mind'},
]

const cartScrollPositions = {
    0: { y: '0%' },
    1: { y: '-2%' },
    2: { y: '-24%' },
    3: { y: '-26%' },
    4: { y: '-36%' },
    5: { y: '-23%' },
    6: { y: '0%' },
    7: { y: '-3%' },
    8: { y: '-5%' },
    9: { y: '-10%' },
}

function CartCallOuts(props) {
    return <div className="call-out-area">
        <AnimatePresence>
            {props.state == 0 && <motion.div key={0} {...animationProps} className="call-out-section" style={{width: '10%', height: '25%', left: '15%', top: '15%'}}/>}
            {props.state == 1 && <motion.div key={1} {...animationProps} className="call-out-section" style={{width: '10%', height: '24%', left: '86%', top: '14%'}}/>}
            {props.state == 2 && <motion.div key={2} {...animationProps} className="call-out-section" style={{width: '15%', height: '30%', left: '81%', top: '13%'}}/>}
            {props.state == 3 && <motion.div key={3} {...animationProps} className="call-out-section" style={{width: '76%', height: '20%', left: '20%', top: '15%'}}/>}
            {props.state == 4 && <motion.div key={4} {...animationProps} className="call-out-section" style={{width: '76%', height: '5%', left: '20%', top: '24%'}}/>}
            {props.state == 5 && <motion.div key={5} {...animationProps} className="call-out-section" style={{width: '20%', height: '40%', left: '2%', top: '1%'}}/>}
            {props.state == 5 && <motion.div key={6} {...animationProps} className="call-out-section" style={{width: '55%', height: '8%', left: '35%', top: '45%'}}/>}
            {props.state == 6 && <motion.div key={7} {...animationProps} className="call-out-section" style={{width: '63%', height: '1%', left: '2%', top: '8%'}}/>}
            {props.state == 6 && <motion.div key={8} {...animationProps} className="call-out-section" style={{width: '32%', height: '1%', left: '66%', top: '8%'}}/>}
            {props.state == 7 && <motion.div key={9} {...animationProps} className="call-out-section" style={{width: '15%', height: '20%', left: '67%', top: '24%'}}/>}
            {props.state == 8 && <motion.div key={10} {...animationProps} className="call-out-section" style={{width: '55%', height: '17%', left: '10%', top: '23%'}}/>}
            {props.state == 9 && <motion.div key={11} {...animationProps} className="call-out-section" style={{width: '33%', height: '20%', left: '65%', top: '33%'}}/>}
        </AnimatePresence>
    </div>
}


const cartCallOutCardVariants = {
        0: { left: '18%', top: '22%' },
        1: { left: '71%', top: '22%' },
        2: { left: '71%', top: '13%' },
        3: { left: '71%', top: '15%' },
        4: { left: '71%', top: '24%' },
        5: { left: '71%', top: '15%' },
        6: { left: '71%', top: '5%' },
        7: { left: '71%', top: '26%' },
        8: { left: '47%', top: '23%' },
        9: { left: '71%', top: '33%' },
}

function CallOutCard(props) {
    const controls = useAnimationControls()

    useEffect(() => {
        controls.start(props.state + '')
    }, [props.state])

    return <motion.div 
      className="call-out-card" 
      variants={props.variants}
      initial={{ left: '71%', top: '0%' }}
      animate={controls}
    >
      <div className="title">{props.cardContent[props.state].title}</div>
      <div className="body">{props.cardContent[props.state].body}</div>
  </motion.div>
}

function TableRow(props) {
    const [hovered, setHovered] = useState()

    return <div 
      className="table-row"
      onMouseOver={() => {setHovered(true)}}
      onMouseOut={() => {setHovered(false)}}
    >
        {hovered && <motion.div 
          className="background-circle"
          style={{position: 'absolute', width: '48px', height: '48px', backgroundColor: '#232323', left:'50%', borderRadius: '500px'}}  
          initial={{scale: 0}}
          animate={{scale: 12}}
          transition={{duration: '.3'}}
        />}
        <div className="left-side">
            <div className="row-title">{props.name}</div>
            <div className="subtitle-box">
                <AnimatePresence mode="wait">
                    {!hovered && <motion.div key={1} initial={{y:20}} animate={{y:0}} exit={{y:-20}} transition={{duration:'.1'}}>
                        {props.hexcode} {props.hexcode == "" ? "" : "–"} {props.altName}
                    </motion.div>}
                    {hovered && <motion.div key={2} initial={{y:20}} animate={{y:0}} exit={{y:-20}} transition={{duration:'.1'}}>
                        {props.usage}
                    </motion.div>}
                </AnimatePresence>
            </div>
        </div>
        <div className="right-side">
            {props.children}
        </div>
    </div>
}