
import React, { useRef } from 'react'
import { Outlines, useGLTF } from '@react-three/drei'

export default function Phone(props) {
  const { nodes, materials } = useGLTF('10-homepage/00-models/phone.glb')
  return (
    <group {...props} dispose={null}>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube.geometry}
            position={[0, 1.639, 0]}
        >
            <meshBasicMaterial color={"#252525"}/>
            <Outlines thickness={1.5} color= {props.isHovered ? "#B08BFF" : "#999"}/>
        </mesh>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            position={[0.658, 2.099, 0]}
        >
            <meshBasicMaterial color={"#252525"}/>
            <Outlines thickness={1.5} color= {props.isHovered ? "#B08BFF" : "#999"}/>
        </mesh>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            position={[-0.654, 2.168, 0]}
        >
            <meshBasicMaterial color={"#252525"}/>
            <Outlines thickness={1.5} color= {props.isHovered ? "#B08BFF" : "#999"}/>
        </mesh>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003.geometry}
            position={[-0.654, 1.897, 0]}
        >
            <meshBasicMaterial color={"#252525"}/>
            <Outlines thickness={1.5} color= {props.isHovered ? "#B08BFF" : "#999"}/>
        </mesh>
    </group>
  )
}

useGLTF.preload('10-homepage/00-models/phone.glb')