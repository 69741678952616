import { Link } from 'react-router-dom'

export default function TopBar(props) {

    return <>
    <div className="header monospace">
        <div className="left-side">
            <Link to='/'>
                &#x2039; Home
            </Link>
        </div>
        <div className="right-side">
            <Link to='/' className='homepage-only'>
                Work.
            </Link>
            <Link to='/' className='homepage-only'>
                Fun.
            </Link>
            {props.nextProject== 1 && <Link to="/discounttire">Next &#x203A;</Link>}
            {props.nextProject== 2 && <Link to="/delta">Next &#x203A;</Link>}
            {props.nextProject== 3 && <Link to="/lenovo">Next &#x203A;</Link>}
            {props.nextProject== 4 && <Link to="/dtinnovation">Next &#x203A;</Link>}
        </div>
    </div>
</>
}