import './style.css'
import { CameraControls, Float, Html, OrbitControls, Outlines, Scroll, ScrollControls, useScroll, shaderMaterial, useTexture, useCursor } from '@react-three/drei'
import { Canvas, useFrame, useLoader, extend } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useGLTF } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import { easing } from 'maath'
import { motion } from 'framer-motion-3d'
import { motion as flatmotion, AnimatePresence, useAnimationControls} from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import PhoneScreen from './shaderTest'
import Phone from './10-Homepage/00-phone'
import useMediaQuery from './useMediaQuery'

function DrawGrid() {
    const lineNumber = 60  //NUMBER OF LINES TO DRAW
    const midPoint = lineNumber / 2 //HALF THAT NUMBER OF LINES
    const backWall = lineNumber / 3 //WHERE THE BACK WALL IS ON THE Z AXIS
    const lineThickness = .01
    let floorGrid = []
    let wallGrid = []
    let matGrid = []
    const hitboxColor = "#282828"
    const highlightColor = "#8268BA"
    const lineColor = "#444"

    
    
    
    for(let i = 0; i < lineNumber; i ++) {
        const matRef = useRef()
        const [verticalHovered, setVerticalHovered] = useState(false)
        const [horizontalFloorHovered, setHorizontalFloorHovered] = useState(false)
        const [horizontalWallHovered, setHorizontalWallHovered] = useState(false)
        floorGrid[i] = <group key={i}>
            {/* VERTICAL FLOOR LINES */}
            <motion.mesh
              scale={[lineThickness,lineThickness,.001]} 
              position={[i-midPoint,0,backWall / 2]}
              initial={{scaleZ: .01}}
              animate={{scaleZ: lineNumber}}
              transition={{delay: Math.abs(lineNumber-i) / midPoint, type: 'easeIn', duration: 1}}
            >
                <boxGeometry/>
                <motion.meshBasicMaterial 
                  initial={{color: lineColor}}
                  animate={verticalHovered ? {color: highlightColor} : {color: lineColor}}
                />
            </motion.mesh>

            {/* HORIZONTAL FLOOR LINES */}
            <motion.mesh 
              scale={[lineNumber,lineThickness,.001]} 
              position={[0,0,i-backWall]}
              initial={{scaleX: .01}}
              animate={{scaleX: lineNumber}}
              transition={{delay: (midPoint-i) / midPoint, type: 'easeIn', duration: 1}}
            >
                <boxGeometry/>
                <motion.meshBasicMaterial 
                  initial={{color: lineColor}}
                  animate={horizontalFloorHovered ? {color: highlightColor} : {color: lineColor}}
                />
            </motion.mesh>

            {/* VERTICAL FLOOR HITBOX LINES */}
            <mesh
              position={[i-midPoint,-.02,backWall / 2]}
              scale={[1,lineThickness,lineNumber]}
              onPointerEnter={()=>setVerticalHovered(true)}
              onPointerLeave={()=>setVerticalHovered(false)}
            >
                <boxGeometry/>
                <meshBasicMaterial color={hitboxColor}/>
            </mesh>

            {/* HORIZONTAL FLOOR HITBOX LINES */}
            <mesh
              position={[0,-.02,i-backWall]}
              scale={[lineNumber,lineThickness,1]}
              onPointerEnter={()=>setHorizontalFloorHovered(true)}
              onPointerLeave={()=>setHorizontalFloorHovered(false)}
            >
                <boxGeometry/>
                <meshBasicMaterial color={hitboxColor}/>
            </mesh>
        </group>
        wallGrid[i] = <group key={i}>
            {/* VERTICAL WALL LINES */}
            <motion.mesh 
              scale={[lineThickness,.001,lineThickness]} 
              position={[i-midPoint,midPoint,-backWall]}
              initial={{scaleY: .01}}
              animate={{scaleY: lineNumber}}
              transition={{delay: (midPoint-i) / midPoint, type: 'easeIn', duration: 1}}
            >
                <boxGeometry/>
                <motion.meshBasicMaterial 
                  initial={{color: lineColor}}
                  animate={verticalHovered ? {color: highlightColor} : {color: lineColor}}
                />
            </motion.mesh>

            {/* HORIZONTAL WALL LINES */}
            <motion.mesh 
              scale={[lineNumber,lineThickness,.001]} 
              position={[0,i,-backWall]}
              initial={{scaleX: .01}}
              animate={{scaleX: lineNumber}}
              transition={{delay: (i) / midPoint, type: 'easeIn', duration: 1}}
            >
                <boxGeometry/>
                <motion.meshBasicMaterial 
                  initial={{color: lineColor}}
                  animate={horizontalWallHovered ? {color: highlightColor} : {color: lineColor}}
                />
            </motion.mesh>

            {/* VERTICAL WALL HITBOX LINES */}
            <mesh
              position={[i-midPoint,midPoint,-backWall - .02]}
              scale={[1,lineNumber,0.02]}
              onPointerEnter={()=>setVerticalHovered(true)}
              onPointerLeave={()=>setVerticalHovered(false)}
            >
                <boxGeometry/>
                <meshBasicMaterial color={hitboxColor}/>
            </mesh>

            {/* HORIZONTAL WALL HITBOX LINES */}
            <mesh
              position={i==0 ? [0,.25,-backWall -.02] : [0,i,-backWall -.02] }
              scale={i==0 ? [lineNumber,.5,lineThickness] :[lineNumber,1,lineThickness]}
              onPointerEnter={()=>setHorizontalWallHovered(true)}
              onPointerLeave={()=>setHorizontalWallHovered(false)}
            >
                <boxGeometry/>
                <meshBasicMaterial color={hitboxColor}/>
            </mesh>
        </group>
    }

    return <group position={[-.5,0,0]}>
        {floorGrid}
        {wallGrid}
    </group>
}

function DrawGrid2() {
    const lineNumber = 60
    let squareGrid = []
    const hitboxColor = "#444"
    const highlightColor = "#B08BFF"

    for(let x = 0; x < lineNumber; x++ ) {
        let rowArray = []
        for(let y=0; y < lineNumber; y++) {
            // console.log()
            const [hovered, setHovered] = useState()
              rowArray[y] = <motion.mesh 
              position={[-35+x-.5,0,-y]}
              scale={[.99,.01,.99]}
              onPointerDown={() => setHovered(true)}
              key={x+"" + y}
            >
                <boxGeometry/>
                <motion.meshBasicMaterial 
                  color={hovered? "#9933aa" : "#333"}
                  initial={{color: "#333"}}
                  animate={hovered?{color: highlightColor} : {color: hitboxColor} }
                />
            </motion.mesh>
        }
        squareGrid[x] = rowArray
    }
    return <>
        {squareGrid}
    </>
}

function CameraHandler(props) {

    const scrollPos = useScroll()
    
    useFrame((state, delta) => 
    {
        const camera = state.camera
        const targetPos = [0,2,3]
        let targetZ 
        if(props.targetSection == 0) {
            scrollPos.el.scrollTo({top: 0, behavior: "smooth"})
            props.setTargetSection(null)
        }
        else if(props.targetSection == 1) {
            scrollPos.el.scrollTo({top: window.innerHeight * 1.25, behavior: "smooth"})
            props.setTargetSection(null)
        }
        else if(props.targetSection == 2) {
            scrollPos.el.scrollTo({top: window.innerHeight * 2.5, behavior: "smooth"})
            props.setTargetSection(null)
        }

        if(scrollPos.offset <= .07) {
            props.setSection(0)
            targetZ = props.isDesktop ? 8 : 12
        }
        else if(scrollPos.offset <= .6) {
            props.setSection(1)
            targetZ = props.isDesktop ? -2 : 2
        }
        else if(scrollPos.offset > .6) {
            props.setSection(2)
            targetZ = -10
        }        

        // console.log(state.pointer)
        easing.damp3(camera.position, [state.pointer.x / 2,2 + state.pointer.y /2,targetZ], .3, delta)
        easing.dampE(camera.rotation, [0,0,0], 0.25, delta)
    })   
}

// NEED A SCROLL HANDLER FUNCTION?
// HTML SETS 'CURRENT SECTION' STATE
// SCROLL HANDLER LISTENS AND THEN SCROLLS ACCORDINGLY

function ProjectCard(props) {
    const scrollData = useScroll()
    // console.log(props.openedState)
    return <Html position={[.5,1,0]} portal={{ current: scrollData.fixed }}>
        <AnimatePresence>
            {props.openedState == 1 && <flatmotion.div 
            className="project-card"
            initial={{opacity: 0, x: -10}}
            animate={{opacity: 1, x: 0}}
            exit={{opacity: 0, x: -10}}
            transition={{duration: 0.2,}}
            // onPointerLeave={() => {props.keepOpened}}
            >
                <div className="title-section monospace">
                    <div className="company">Discount Tire</div>
                    <div className="title">E-Commerce Redesign</div>
                </div>
                <div className="multiple-paragraphs">
                    <div className="paragraph-section">
                        <div className="title">Why?</div>
                        <div className="paragraph">Discount Tire was looking for a new look and feel for their web experience that helps driver conversion.</div>
                    </div>
                    <div className="paragraph-section">
                        <div className="title">How?</div>
                        <div className="paragraph">
                            <li>New Design System</li>
                            <li>Website Redesign</li>
                        </div>
                    </div>
                    <div className="paragraph-section">
                        <div className="title">What?</div>
                        <div className="paragraph">
                            <li>$101 million+ annual revenue increase</li>
                            <li>13% increased cart to checkout conversion</li>
                            <li>Evolving design system</li>
                        </div>
                    </div>
                </div>
            </flatmotion.div>}
        </AnimatePresence>
    </Html>
}

function ProjectName(props) {
    const scrollData = useScroll()

    return <Html center position={[.5,1,5.5]} portal={{ current: scrollData.fixed }}>
        {/* <div className="project-name" style={{fontSize: "140px", fontFamily: "IBM Plex Mono",}}>DISCOUNT TIRE</div> */}
    </Html>
}

const variants = {
    initial: {scale: .8},
    animate: {scale: 1},
  }

function ProjectPodium(props) {
    const [hovered, setHover] = useState(false)

    useCursor(hovered)
    return <motion.group {...props}
            onPointerEnter={()=>setHover(true)}
            onPointerLeave={()=>setHover(false)}
        >
            <motion.group
              position={[0,-1.375,0]}
              animate={props.section == props.sectionNum ? {scale: 1} : {scale: 0}}
              transition={{duration: .3}}
            >
                <mesh scale={[1,.25,1]} >
                    <cylinderGeometry args={[1,1,1,72]}/>
                    <meshBasicMaterial color={"#252525"}/>
                    <Outlines thickness={1.5} color= {hovered ? "#B08BFF" : "#999"}/>
                </mesh>
            </motion.group>
            <motion.group
              animate={props.section == props.sectionNum ? {scale: 1} : {scale: 0}}
              transition={{duration: .3}}
            >
                <Float speed={5} floatIntensity={1}>
                    <Phone position={[0,-1.1,0]} isHovered={hovered}/>
                    <PhoneScreen position={[0,.54,.085]} uiScreen={props.uiScreen} offScreen={props.offScreen} dimensions={[1.24,2.4,.1]}/>
                </Float>
            </motion.group>
        </motion.group>
}

function ProgrammingPodium(props) {
    const [hovered, setHover] = useState(false)

    useCursor(hovered)
    return <motion.group {...props}
            onPointerEnter={()=>setHover(true)}
            onPointerLeave={()=>setHover(false)}
        >
            <motion.group
              position={[0,-1.375,0]}
              animate={props.section == props.sectionNum ? {scale: 1} : {scale: 0}}
              transition={{duration: .3, delay: .15}}
            >
                <mesh scale={[1,.25,1]}>
                    <cylinderGeometry args={[1,1,1,72]}/>
                    <meshBasicMaterial color={"#252525"}/>
                    <Outlines thickness={1.5} color= {hovered ? "#B08BFF" : "#999"}/>
                </mesh>
            </motion.group>
            <motion.group
              animate={props.section == props.sectionNum ? {scale: 1} : {scale: 0}}
              transition={{duration: .3, delay: .15}}
            >
                <Float speed={5} floatIntensity={1}>
                    <mesh onClick={() => {window.open(props.pageLink, "_blank")}}>
                        <PhoneScreen position={[0,.54,.085]} uiScreen={props.uiScreen} offScreen={props.offScreen} dimensions={[2.4,1.5,.1]}/>
                    </mesh>
                </Float>
            </motion.group>
        </motion.group>
}

function AboutPage(props) {
    const [hovered, setHover] = useState(false)
    const scrollData = useScroll()

    useCursor(hovered)
    return <motion.group
            position={[0,2,-23]}
            onPointerEnter={()=>setHover(true)}
            onPointerLeave={()=>setHover(false)}
            initial={{scale: .7}}
            animate={props.section == props.sectionNum ? {scale: 1} : {scale: 0}}
            transition={{duration: .3, delay: .3}}
        >
            <Html transform position={[0,0,0]} scale={props.isDesktop ? .3 : .45} portal={{ current: scrollData.fixed }}>
                <div className="about-page">
                    <div className="profile-pic">
                        <img src='10-homepage/04-dallin.jpg'/>
                    </div>
                    <div className="about-info monospace">
                        <h1>Dallin here.</h1>
                        <div>I’m a senior product designer based in Atlanta, currently working for IBM. I focus on outcomes and intentionality behind design.</div>
                        <div>On the side, I do creative development, mostly focusing on 3D web experiences using React, ThreeJS, and R3F, as well as creating models in Blender.</div>
                        <div className="links">
                            <a target="blank" href="https://drive.google.com/file/d/1CbQ-uk6RhtMq8M86ehJ0EJXtE1V6bWzG/view?usp=sharing">My Resume</a>
                            <a target="blank" href="https://www.linkedin.com/in/dallinhiggins/">My LinkedIn</a>
                        </div>
                    </div>
                </div>
            </Html>
            
        </motion.group>
}

function HomeSection(props) {
    const scrollData = useScroll()
    return <Html transform position={props.isDesktop ? [0,4.25,-4] : [0,5.25,-4]} scale={props.isDesktop ? .3 : .5} portal={{ current: scrollData.fixed }}>
                {props.section == props.sectionNum && <flatmotion.div 
                  className="home-page-content monospace"
                  initial={{marginTop: -32, opacity: 0}}
                  animate={{marginTop: 0, opacity: 1}}
                  transition={{duration: .3, delay: .1}}
                >
                    <div className="title">Hi, I'm <span className='primary-color'>Dallin</span>.</div>
                    <div className="subtitle">
                        I'm a <span className='bold'>Senior Product Designer at IBM</span> & Creative Developer.
                        <br/> Here's some of the work I've done for clients:
                    </div>
                </flatmotion.div>}
            </Html>
}

function ProgrammingSection(props) {
    const scrollData = useScroll()
    return <Html transform position={props.isDesktop ? [0,4.25,-16] : [0,5.25,-16]} scale={props.isDesktop ? .3 : .5} portal={{ current: scrollData.fixed }}>
                {props.section == props.sectionNum && <flatmotion.div 
                  className="home-page-content monospace"
                  initial={{marginTop: -32, opacity: 0}}
                  animate={{marginTop: 0, opacity: 1}}
                  transition={{duration: .3, delay: .1}}
                >
                    <div className="title"><span className='primary-color'>3D Web</span> Projects.</div>
                    <div className="subtitle">
                        I love using Blender, React, and Three.js to make 3D websites
                        <br/> Here's a small selection of some of those sites:
                    </div>
                    
                </flatmotion.div>}
            </Html>
}

function TopBar(props) {
    return <div className="home-page-top-bar">
        <div className="header monospace">
            <div className="left-side">
                <div className="menu-item" onClick={() => props.setTargetSection(0)}>
                    Dallin.
                </div>
            </div>
            <div className="right-side">
                <div className="menu-item" onClick={() => props.setTargetSection(0)}>
                    <div className="label" style={props.section == 0 ? {color: "#B08BFF"} : {}}>Work.</div>
                    {props.section == 0 && <flatmotion.div 
                      className="underline"
                      initial={{width: '0%'}} 
                      animate={{width: '100%'}}
                    />}
                </div>
                <div className="menu-item" onClick={() => props.setTargetSection(1)}>
                    <div className="label" style={props.section == 1 ? {color: "#B08BFF"} : {}}>Fun.</div>
                    {props.section == 1 && <flatmotion.div 
                      className="underline"
                      initial={{width: '0%'}} 
                      animate={{width: '100%'}}
                    />}
                </div>
                <div className="menu-item" onClick={() => props.setTargetSection(2)}>
                    <div className="label" style={props.section == 2 ? {color: "#B08BFF"} : {}}>About.</div>
                    {props.section == 2 && <flatmotion.div 
                      className="underline"
                      initial={{width: '0%'}} 
                      animate={{width: '100%'}}
                    />}
                </div>
            </div>
        </div>
    </div>
}

export default function Experience()
{
    const isDesktop = useMediaQuery()

    const cubeRef = useRef()
    // const discountTireTexture = useLoader(TextureLoader, '01-discounttire/Mobile.jpg')
    // const gear = useGLTF('00-homepage/00-gear.glb')
    const [settingsOpened, setSettingsOpened] = useState(false)
    const [projectsOpened, setProjectsOpened] = useState([0,0,0,0])

    const [homepageSection, setHomepageSection] = useState(0)
    const [targetSection, setTargetSection] = useState()

    const navigate = useNavigate()
    const LinkToPage1 = () => navigate('/discounttire')
    const LinkToPage2 = () => navigate('/delta')
    const LinkToPage3 = () => navigate('/lenovo')
    const LinkToPage4 = () => navigate('/dtinnovation')


    return <>
        <TopBar section={homepageSection} setSection={setHomepageSection} targetSection={targetSection} setTargetSection={setTargetSection}/>
        <Canvas
            shadows
            camera={ {
                fov: isDesktop ? 30 : 45,
                near: 0.1,
                far: 200,
                position: [ 0,1.5,0 ]
            } }
        >
            <DrawGrid/>
            

            <ScrollControls htmlScroll pages={2.5}>

                <CameraHandler section={homepageSection} setSection={setHomepageSection} targetSection={targetSection} setTargetSection={setTargetSection} isDesktop={isDesktop}/>

                <HomeSection
                    section={homepageSection}
                    sectionNum={0}
                    isDesktop={isDesktop}
                />

                {/* DISCOUNT TIRE */}
                <ProjectPodium
                    position={[-3,1.5,-4]} 
                    onClick={LinkToPage1}
                    rotation={[0,.6,0]}
                    offScreen="10-homepage/05-off-screen-1.jpg"
                    uiScreen="10-homepage/00-discounttire.jpg"
                    section={homepageSection}
                    sectionNum={0}
                />

                {/* DELTA */}
                <ProjectPodium
                    position={[-1.1,1.5,-5.5]}
                    onClick={LinkToPage2}
                    rotation={[0,.35,0]}
                    offScreen="10-homepage/05-off-screen-2.jpg"
                    uiScreen="10-homepage/01-delta.jpg"
                    section={homepageSection}
                    sectionNum={0}
                />

                {/* LENOVO */}
                <ProjectPodium
                    position={[1.1,1.5,-5.5]}
                    onClick={LinkToPage3}
                    rotation={[0,-.35,0]}
                    offScreen="10-homepage/05-off-screen-3.jpg"
                    uiScreen="10-homepage/02-lenovo.jpg"
                    section={homepageSection}
                    sectionNum={0}
                />

                {/* DT INNOVATION */}
                <ProjectPodium
                    position={[3,1.5,-4]}
                    onClick={LinkToPage4}
                    rotation={[0,-.6,0]}
                    offScreen="10-homepage/05-off-screen-4.jpg"
                    uiScreen="10-homepage/03-dtinnovation.jpg"
                    section={homepageSection}
                    sectionNum={0}
                />

                {/* END OF SECTION ONE */}
                {/* BEGINNING OF SECTION TWO */}

                <ProgrammingSection
                    section={homepageSection}
                    sectionNum={1}
                    isDesktop={isDesktop}
                />

                {/* TWO MINUTE TOWN */}
                <ProgrammingPodium 
                    position={[-2.5,1.5,-14]}
                    pageLink="https://twominutetown.web.app/"
                    rotation={[0,.6,0]}
                    offScreen="10-homepage/07-programming-projects-off-screen-1.jpg"
                    uiScreen="10-homepage/06-programming-projects-1.jpg"
                    section={homepageSection}
                    sectionNum={1}
                />

                {/* TWO MINUTE TOWN */}
                <ProgrammingPodium 
                    position={[0,1.5,-15]}
                    pageLink="https://atlantaxrhub.web.app/"
                    rotation={[0,0,0]}
                    offScreen="10-homepage/07-programming-projects-off-screen-2.jpg"
                    uiScreen="10-homepage/06-programming-projects-2.jpg"
                    section={homepageSection}
                    sectionNum={1}
                />

                {/* TWO MINUTE TOWN */}
                <ProgrammingPodium 
                    position={[2.5,1.5,-14]}
                    pageLink="https://economicadventure.web.app/"
                    rotation={[0,-.6,0]}
                    offScreen="10-homepage/07-programming-projects-off-screen-3.jpg"
                    uiScreen="10-homepage/06-programming-projects-3.jpg"
                    section={homepageSection}
                    sectionNum={1}
                />

                {/* END OF SECTION TWO */}
                {/* BEGINNING OF SECTION THREE */}

                {/* ABOUT PAGE */}
                <AboutPage
                    section={homepageSection}
                    sectionNum={2}
                    isDesktop={isDesktop}
                />
                
            </ScrollControls>
            
        </Canvas>
    </>
}