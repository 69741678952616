import { shaderMaterial, useTexture } from "@react-three/drei"
import { extend, useFrame } from "@react-three/fiber"
import { useRef, useState } from "react"
import * as THREE from 'three'
import { geometry } from 'maath'

extend(geometry)

export const ImageFadeMaterial = shaderMaterial(
    {
      effectFactor: 0.5,
      dispFactor: 0,
      tex: undefined,
      tex2: undefined,
      disp: undefined
    },
    ` varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }`,
    ` varying vec2 vUv;
      uniform sampler2D tex;
      uniform sampler2D tex2;
      uniform sampler2D disp;
      uniform float _rot;
      uniform float dispFactor;
      uniform float effectFactor;
      void main() {
        vec2 uv = vUv;
        float dist = length(uv - vec2(0.5, 0.5));
        float circle = smoothstep(dispFactor - 0.03, dispFactor + 0.1, dist);
        
        vec4 color1 = texture2D(tex, uv);
        vec4 color2 = texture2D(tex2, uv);
        gl_FragColor = mix(color1, color2, circle);
      }`
)

extend({ ImageFadeMaterial })

export default function PhoneScreen(props) {
    const [texture1, texture2] = useTexture([props.uiScreen, props.offScreen])

    const ref = useRef()
    const [hovered, setHover] = useState(false)

    useFrame(() => {
        ref.current.dispFactor = THREE.MathUtils.lerp(ref.current.dispFactor, hovered ? 1 : -.08, 0.15)
        // if(ref.current.dispFactor < .01)
        //     ref.current.dispFactor = -.08
    })

    return <mesh {...props} onPointerOver={(e) => setHover(true)} onPointerOut={(e) => setHover(false)}>
        <roundedPlaneGeometry args={props.dimensions}/>
        <imageFadeMaterial ref={ref}  tex={texture1} tex2={texture2} toneMapped={false}/>
    </mesh>
}