import { useEffect, useRef, useState } from 'react'
import './style.css'
import { useMotionValueEvent, motion, useScroll, AnimatePresence } from 'framer-motion'
import { DesignSystemMolecules, DesignSystemOrganisms, DesignSystemTokens, DetailedDesign, Outcome, ProductListingPage, ShoppingCart } from './01-DiscountTire/discounttireModules'
import TopBar from './00-Sitewide/topBar'
import Footer from './00-Sitewide/footer'

function TableOfContentsChip(props) {
    const [active, setActive] = useState(props.active == props.sectionNum)

    const { scrollYProgress } = useScroll({
        target: props.sectionRef,
        offset: ['start center', 'end center']
    })

    const [progress, setProgress] = useState('0%')

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        setProgress(latest * 100 + "%")
        if(latest > 0 && latest < 1) {
            setActive(true)
            props.setCurrentSection(props.sectionNum)
        }
        else {
            setActive(false)
        }
    })

    return <motion.div 
      className="chip" 
      style={active ? {border: '1px solid #B08BFF', color: '#B08BFF', fontWeight: '600'} : {}}
      onClick={() => {props.sectionRef.current?.scrollIntoView({behavior: 'instant', block: "center"})}}
    >
        {props.name}
        <div className="chip-progress" style={{width: progress}}></div>
    </motion.div>
}

function ContentChip(props) {
    const active = props.state == props.id
    return <motion.div 
    className="chip" 
    style={active ? {border: '1px solid #B08BFF', color: '#B08BFF', padding: '5px 7px', fontWeight: '600'} : {}}
    onClick={() => {props.setState(props.id)}}
  >
      {props.name}
  </motion.div>
}

export default function DTInnovationLab() {
    const [currentSection, setCurrentSection] = useState(0)

    const sectionRef1 = useRef()
    const sectionRef2 = useRef()
    const sectionRef3 = useRef()
    const sectionRef4 = useRef()
    const sectionRef5 = useRef()
    const sectionRef6 = useRef()

    const [researchActive, setResearchActive] = useState(0)
    const [treadwellActive, setTreadwellActive] = useState(0)
    const [ecommerceActive, setEcommerceActive] = useState(0)
    const [nativeAppActive, setNativeAppActive ] = useState(0)
    const [outcomeActive, setOutcomeActive ] = useState(0)
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <>
    <div className="full-page">
        <div className="two-columns">
            <motion.div 
              className="left-column"
              initial={{x: -32, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              transition={{duration: .4}}
            >
                <TopBar nextProject={1}/>
                <div className="left-column-content">

                    <div className="mobile-image">
                        <img src='04-dtinnovation/99-mobile/00-hero-image.jpg'/>
                    </div>

                    {/* PROJECT SUMMARY HEADER */}
                    <div className="first-section" ref={sectionRef1}>
                        <div className="project-title">
                            Discount Tire Innovation lab
                        </div>
                        <div className="paragraph">
                            <div className="title">My Role</div>
                            <div className="body">Design Lead</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Problem Statement</div>
                            <div className="body">Discount Tire was thrilled with the redesign that I worked on, and asked IBM for more ‘big picture’ concepts & initiatives they could work toward.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Process & Deliverables</div>
                            <div className="body">I worked with IBM & Discount Tire to create concepts for new digital products. We conducted design thinking workshops with stakeholders & employees, then I led a small team of designers in design direction & execution.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Outcome</div>
                            <ul className="body">
                                <li>Design concepts moving into full design & development cycle.</li>
                                <li>Increased contract scope as we take on more big-picture work and ideation.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='04-dtinnovation/99-mobile/01-research-1.jpg'/>
                    </div>

                    {/* RESEARCH & PROCESS */}
                    <div className="section" ref={sectionRef2}>
                        <AnimatePresence>
                            {currentSection == 2 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Workshops & Interviews
                            </div>
                            <div className="section-subtitle">
                                Let’s talk to our stakeholders & users
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Workshop Facilitation" id={0} state={researchActive} setState={setResearchActive}/>
                                <ContentChip name="Focus Areas" id={1} state={researchActive} setState={setResearchActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Research</div>
                            <div className="body">In order to create a list of problem statements & priority areas, we conducted workshops with key stakeholders, and interviews with store managers.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">My Approach</div>
                            <div className="body">From the insights we gained during interviews, I worked on creating a 4 pillar design framework from priorities that were important to our users.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Value</div>
                            <div className="body">A core principle that came up in the workshop is that the site is too ‘one size fits all’ for its different users, and there’s a big opportunity around providing unique features to different users.</div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='04-dtinnovation/99-mobile/02-treadwell-redesign-3.jpg'/>
                    </div>

                    {/* TREADWELL/GARAGE */}
                    <div className="section" ref={sectionRef3}>
                        <AnimatePresence>
                            {currentSection == 3 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Treadwell Redesign
                            </div>
                            <div className="section-subtitle">
                                Why should I create an account just for buying tires?
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="2 Entry Points" id={0} state={treadwellActive} setState={setTreadwellActive}/>
                                <ContentChip name="Visual Identity" id={1} state={treadwellActive} setState={setTreadwellActive}/>
                                <ContentChip name="Results, Explained" id={2} state={treadwellActive} setState={setTreadwellActive}/>
                                <ContentChip name="Account Value" id={3} state={treadwellActive} setState={setTreadwellActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Problem Statement</div>
                            <div className="body">Account creation drives purchases for Discount Tire, but customers don’t see a reason to create an account.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">My Approach</div>
                            <div className="body">I wanted to provide account creation incentives through a comprehensive redesign of their e-commerce quiz, then provide additional benefits for being signed in through a redesigned account page, called “My Garage”.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Value to Client</div>
                            <ul className="body">
                                <li>Increase in entry points and value would drive account creation.</li>
                                <li>Redesigned e-commerce quiz would increase confidence in product recommendations.</li>
                                <li>Account features would lead to loyal customers and repeat purchases.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='04-dtinnovation/99-mobile/03-conversational-ai-2.jpg'/>
                    </div>

                    {/* E-COMMERCE AI */}
                    <div className="section" ref={sectionRef4}>
                        <AnimatePresence>
                            {currentSection == 4 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                E-Commerce AI
                            </div>
                            <div className="section-subtitle">
                                How can we provie in-store expertise at-scale digitally?
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Entry Points" id={0} state={ecommerceActive} setState={setEcommerceActive}/>
                                <ContentChip name="AI as a Tool" id={1} state={ecommerceActive} setState={setEcommerceActive}/>
                                <ContentChip name="AI Conversations" id={2} state={ecommerceActive} setState={setEcommerceActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Problem Statement</div>
                            <div className="body">“Discount Tire prefers customers make purchases online, so they can have those products in stock, but many customers like to talk to an associate to have their questions answered first.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">My Approach</div>
                            <div className="body">IBM asked me to include their AI platforms as part of this solution. I created a chatbot for the website, with special attention to entry points and non-intrusive access for users,</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Value to Client</div>
                            <ul className="body">
                                <li>Increase users’ confidence in buying tires that fit their vehicle.</li>
                                <li>Allow users to shop through the website without having their full vehicle fitment.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='04-dtinnovation/99-mobile/04-mobile-app-5.jpg'/>
                    </div>

                    {/* NATIVE APP */}
                    <div className="section" ref={sectionRef5}>
                        <AnimatePresence>
                            {currentSection == 5 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Native App
                            </div>
                            <div className="section-subtitle">
                                How can we keep loyalty high post-purchase?
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Mobile Screens" id={0} state={nativeAppActive} setState={setNativeAppActive}/>
                                <ContentChip name="Vehicle Integration" id={1} state={nativeAppActive} setState={setNativeAppActive}/>
                                <ContentChip name="Appointment Tracking" id={2} state={nativeAppActive} setState={setNativeAppActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Problem Statement</div>
                            <div className="body">Discount Tire currently has a mobile app, which only has 2.3 stars in the App Store, due to it lacking any unique features or functionality for customers.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">My Approach</div>
                            <div className="body">I intended the mobile app entry point to be between the online purchase and in-person install of products, and the app can help manage those appointments and provide reminders for repeat service.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Value to Client</div>
                            <ul className="body">
                                <li>Increase users’ confidence in buying tires that fit their vehicle.</li>
                                <li>Allow users to shop through the website without having their full vehicle fitment.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='04-dtinnovation/99-mobile/05-conclusion.jpg'/>
                    </div>

                    {/* OUTCOME */}
                    <div className="section" ref={sectionRef6}>
                        <AnimatePresence>
                            {currentSection == 6 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Outcome
                            </div>
                            <div className="section-subtitle">
                                So... did it work?
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Result</div>
                            <div className="body">There were 6–7 concepts created in total over a 5 month period. Several of these concepts have been brought to refinement so that they can be implemented into the website. The visuals and details of each concept are likely to change before implementation. I’m continuing to work with Discount Tire to find these high impact opportunities across their web experience.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">My Takeaway</div>
                            <div className="body">This project required a shift in mentality for me. Typically, I gravitate toward projects that provide high value for the business and are easy to implement, but our stakeholders wanted bigger ideas. It was definitely a challenge to remind myself that the feasibility and details didn’t need to be ‘nailed down’ at this stage, and it was okay to not know how every single part works.</div>
                        </div>
                    </div>
                </div>
                <div className="mobile-only">
                    <Footer nextProject={1}/>
                </div>
            </motion.div>

            {/* LEFT COLUMN ENDS */}
            {/* RIGHT COLUMN STARTS */}

            <div className="right-column">    
                <div className="right-column-content">
                    <motion.div 
                      className="table-of-contents"
                      initial={{y: -32, opacity: 0}}
                      animate={{y: 0, opacity: 1}}
                      transition={{duration: .3}}
                    >
                        <TableOfContentsChip name="Overview" sectionNum={1} active={currentSection} sectionRef={sectionRef1} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Research" sectionNum={2} active={currentSection} sectionRef={sectionRef2} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Treadwell Redesign" sectionNum={3} active={currentSection} sectionRef={sectionRef3} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="E-Commerce AI" sectionNum={4} active={currentSection} sectionRef={sectionRef4} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Native App" sectionNum={5} active={currentSection} sectionRef={sectionRef5} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Outcome" sectionNum={6} active={currentSection} sectionRef={sectionRef6} setCurrentSection={setCurrentSection}/>
                    </motion.div>
                    <div className="main-content">
                        {/* <div className="hero-image">
                            <img src='01-discounttire/image 1266.jpg'/>
                        </div> */}
                        <div className="section-title-box">
                            <AnimatePresence mode='wait'>
                                {currentSection == 1 && <motion.div 
                                  key={0} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Overview
                                </motion.div>}
                                {currentSection == 2 && <motion.div 
                                  key={1} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Research ::
                                    <AnimatePresence mode='wait'>
                                        {researchActive== 0 && <motion.div key={0}>
                                            Existing Homepage
                                        </motion.div>}
                                        {researchActive== 1 && <motion.div key={1}>
                                            Design Principles
                                        </motion.div>}
                                        {researchActive== 2 && <motion.div key={2}>
                                            B2B Persona
                                        </motion.div>}
                                        {researchActive== 3 && <motion.div key={3}>
                                            B2C Persona
                                        </motion.div>}
                                    </AnimatePresence>
                                </motion.div>}
                                {currentSection == 3 && <motion.div 
                                  key={2} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    First Final Product
                                </motion.div>}
                                {currentSection == 4 && <motion.div 
                                  key={3} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Design Pivot
                                    <AnimatePresence mode='wait'>
                                    </AnimatePresence>
                                </motion.div>}
                                {currentSection == 5 && <motion.div 
                                  key={4} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Final Final Product
                                </motion.div>}
                                {currentSection == 6 && <motion.div 
                                  key={5} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Outcome
                                </motion.div>}
                            </AnimatePresence>
                        </div>


                        {/* VISUALS BEGINS */}

                        <AnimatePresence mode='wait'>

                            {/* OVERVIEW VISUALS */}
                            {currentSection == 1 && <motion.div 
                              className="hero-image"
                              key={0}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <motion.img 
                                    src='04-dtinnovation/00-hero-image.jpg'
                                    initial={{scale: 1.15}}
                                    animate={{scale: 1}}
                                    transition={{duration:.25}}
                                />
                            </motion.div>}

                            {/* INITIAL RESEARCH VISUALS */}
                            {currentSection == 2 && <motion.div
                              key={1}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>
                                    {/* CURRENT HOMEPAGE */}
                                    {researchActive == 0 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={0}
                                    >
                                        <div className="image">
                                            <img src='04-dtinnovation/01-research-1.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Workshops</div>
                                            <div className="description">We flew to client site to facilitate a full day workshop where we brainstormed with client experts. We brainstormed opportunities and voted on the ones that had the highest impact & feasibility.</div>
                                        </div>
                                    </motion.div>}

                                    {/* WILL BE DESIGN PRINCIPLES */}
                                    {researchActive == 1 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={1}
                                    >
                                        <div className="image">
                                            <img src='04-dtinnovation/01-research-2.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Focus Areas</div>
                                            <div className="description">From the 117 idea sticky notes that we came up with, we condensed down to just 15 especially impactful concepts that could be brought into conceptualization and design.</div>
                                        </div>
                                    </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* MY GARAGE */}
                            {currentSection == 3 && <motion.div 
                            //   className="hero-image"
                              key={2}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>
                                    {/* TREADWELL REDESIGN */}
                                    {treadwellActive == 0 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={0}
                                    >
                                        <div className="image">
                                            <img src='04-dtinnovation/02-treadwell-redesign-1.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">2 Entry Points</div>
                                            <div className="description">Currently, Discount Tire's driving quiz is difficult to find and unintuitive, but provides high value for users. I wanted to make it a full multi-step flow, while also adding a simpler version available through the header.</div>
                                        </div>
                                    </motion.div>}

                                    {/* ONELENOVO ENTRY */}
                                    {treadwellActive == 1 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={1}
                                    >
                                        <div className="image">
                                            <img src='04-dtinnovation/02-treadwell-redesign-2.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Visual Identity</div>
                                            <div className="description">For the full e-commerce quiz flow, I wanted to make it more visual and distinct. I gave them a few different options within the presentation, from 3D models to illustrative to photography. Here's one question from that flow.</div>
                                        </div>
                                    </motion.div>}

                                    {/* ONELENOVO QUIZ QUESTION */}
                                    {treadwellActive == 2 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={2}
                                    >
                                        <div className="image">
                                            <img src='04-dtinnovation/02-treadwell-redesign-3.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Results, Explained</div>
                                            <div className="description">A major friction point we heard from customers is that they're bombarded with choices, without knowing enough to make an informed decision. This quiz results page would give more context behind why each tire is recommended for your driving behavior.</div>
                                        </div>
                                    </motion.div>}

                                    {/* RESULTS SCREEN */}
                                    {treadwellActive == 3 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={3}
                                    >
                                        <div className="image">
                                            <img src='04-dtinnovation/02-treadwell-redesign-4.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Account Value</div>
                                            <div className="description">The final step would be tying these quiz results into an account page so that customers can save their results. Here, we can provide additional value to the customer like tracking service appointments, while also increasing revenue with service reminders and product recommendations.</div>
                                        </div>
                                    </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* DESIGN PIVOT */}
                            {currentSection == 4 && <motion.div 
                            //   className="hero-image"
                              key={3}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>
                                    {/* TREADWELL REDESIGN */}
                                    {ecommerceActive == 0 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={0}
                                    >
                                        <div className="image">
                                            <img src='04-dtinnovation/03-conversational-ai-1.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Account Value</div>
                                            <div className="description">I paid special attention to the entry points for the AI experience. Making sure it felt completely optional to users, while still findable. Places like the bottom-right corner of the homepage.</div>
                                        </div>
                                    </motion.div>}

                                    {/* ONELENOVO ENTRY */}
                                    {ecommerceActive == 1 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={1}
                                    >
                                        <div className="image">
                                            <img src='04-dtinnovation/03-conversational-ai-2.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Account Value</div>
                                            <div className="description">The AI platform was designed to feel like its own tool within the experience. It has a landing page with helpful information for any customer that might be struggling with their tire purchasing journey.</div>
                                        </div>
                                    </motion.div>}

                                    {/* ONELENOVO QUIZ QUESTION */}
                                    {ecommerceActive == 2 && <motion.div
                                      className="three-columns"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={2}
                                    >
                                        <div className="column">
                                            <img src='04-dtinnovation/03-conversational-ai-3.jpg'/>
                                            <div className="title">Fitment Help</div>
                                            <div className="description">Some customers, especially older users, frequently struggle to find the correct tire size for their vehicle. This is a use case that makes sense for AI, to help them locate the info at their own pace.</div>
                                        </div>
                                        <div className="column">
                                            <img src='04-dtinnovation/03-conversational-ai-4.jpg'/>
                                            <div className="title">Price Match</div>
                                            <div className="description">Discount Tire price matches, but the process is manual and takes a few days, usually leaving customers to go with a competitor. Approving price matches with the use of AI helps retain those customers.</div>
                                        </div>
                                        <div className="column">
                                            <img src='04-dtinnovation/03-conversational-ai-5.jpg'/>
                                            <div className="title">Product Recommendations</div>
                                            <div className="description">Another use case that makes sense with AI is extending Treadwell recommendations to provide more information to customers about which tires to choose.</div>
                                        </div>
                                    </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* NATIVE APP */}
                            {currentSection == 5 && <motion.div 
                            //   className="hero-image"
                              key={4}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>
                                    {/* 3D HOMEPAGE */}
                                    {nativeAppActive == 0 && <motion.div
                                      className="three-columns"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={0}
                                    >
                                        <div className="column">
                                            <img src='04-dtinnovation/04-mobile-app-1.png'/>
                                            <div className="title">Easier Sign In</div>
                                            <div className="description">Customers we talked to didn't want the friction of creating an account, so providing a simpler entry point with confirmation number makes sense to increase adoption.</div>
                                        </div>
                                        <div className="column">
                                            <img src='04-dtinnovation/04-mobile-app-2.png'/>
                                            <div className="title">Personalized Experience</div>
                                            <div className="description">Each customers' app should feel different, with a homepage designed specifically for their family's vehicles and drivers.</div>
                                        </div>
                                        <div className="column">
                                            <img src='04-dtinnovation/04-mobile-app-3.png'/>
                                            <div className="title">Native App Features</div>
                                            <div className="description">One benefit of doing a native app is that we can make use of push notification, gestures, and location tracking to provide a better user experience.</div>
                                        </div>
                                    </motion.div>}

                                    {/* ONELENOVO ENTRY */}
                                    {nativeAppActive == 1 && <motion.div
                                      className="hero-image"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={1}
                                    >
                                        <img src='04-dtinnovation/04-mobile-app-4.jpg'/>
                                    </motion.div>}

                                    {/* ONELENOVO QUIZ QUESTION */}
                                    {nativeAppActive == 2 && <motion.div
                                      className="hero-image"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={2}
                                    >
                                        <img src='04-dtinnovation/04-mobile-app-5.jpg'/>
                                    </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* OUTCOME */}
                            {currentSection == 6 && <motion.div 
                              key={5}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <div className="image-and-caption">
                                    <div className="image">
                                        <img src='04-dtinnovation/05-conclusion.jpg'/>
                                    </div>
                                    <div className="caption">
                                        <div className="title">Presentations</div>
                                        <div className="description">We used storytelling to present these concepts to leadership. Some of these have progressed to refinement with the larger design team.</div>
                                    </div>
                                </div>
                                <Footer nextProject={1}/>
                            </motion.div>}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
}