import { createBrowserRouter, RouterProvider } from "react-router-dom"

import './style.css'
import { createRoot } from 'react-dom/client'
import DiscountTire from './discounttire.jsx'
import Delta from './delta.jsx'
import DTInnovationLab from './dtinnovation.jsx'
import Lenovo from './lenovo.jsx'
import Homepage from './homepage.jsx'

const root = createRoot(document.querySelector('#root'))

const router = createBrowserRouter([
    {
        path: '/',
        element: <Homepage />
    },
    {
        path: '/discounttire',
        element: <DiscountTire />
    },
    {
        path: '/delta',
        element: <Delta />
    },
    {
        path: '/lenovo',
        element: <Lenovo />
    },
    {
        path: '/dtinnovation',
        element: <DTInnovationLab />
    }
])

root.render(
    <RouterProvider router={router} />
)