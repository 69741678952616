import { motion, AnimatePresence, useAnimationControls } from "framer-motion"
import { useEffect, useState } from "react"

export function DesktopHomepage(props) {
    const controls = useAnimationControls()

    useEffect(() => {
        controls.start(props.state + '')
    }, [props.state])

    return <div className="desktop-holder">
        <div className="desktop-screen">
            <CartCallOuts
                state = {props.state}
            />
            <CallOutCard
                state = {props.state}
                variants = {cartCallOutCardVariants}
                cardContent = {cartCardContent}
            />
            <div style={{width: '100%', display: 'flex'}}>
                {props.state < 4 &&<motion.img 
                    style={{width: '70%'}}
                    src="03-lenovo/03-homepage-redesign.jpg"
                    variants={cartScrollPositions}
                    animate={controls}
                />}
                {props.state > 3 &&<motion.img 
                    style={{width: '70%'}}
                    src="03-lenovo/03-product-page-redesign.jpg"
                    variants={cartScrollPositions}
                    animate={controls}
                />}
            </div>
        </div>
    </div>
}

export function DesignPrinciples(props) {
    return <div className="four-cards">
        <div className="card">
            <img src="03-lenovo/01-icons/00-icon.svg"/>
            <div className="title">Who, Not What</div>
            <div className="description">Design for deeply held, human needs of our customers, not to our inventory of devices & services.</div>
        </div>
        <div className="card">
            <img src="03-lenovo/01-icons/01-icon.svg"/>
            <div className="title">Single Decision Sales</div>
            <div className="description">Rather than force a series of user-driven decisions to tailor solutions, use our expertise to sell in a way that requires a single “yes.”</div>
        </div>
        <div className="card">
            <img src="03-lenovo/01-icons/02-icon.svg"/>
            <div className="title">Term Simplification</div>
            <div className="description">Multiple offerings, nav items, programs and terms compete with each other, requiring a simplification of what we do and how we talk about it</div>
        </div>
        <div className="card">
            <img src="03-lenovo/01-icons/03-icon.svg"/>
            <div className="title">Partnership Over Product</div>
            <div className="description">Transition the way Lenovo talks about itself from vendor to consultative partner, offering holistic solutions full-service agency-style</div>
        </div>
    </div>
}

const animationProps = {
    initial:{opacity: 0, y: 16},
    animate:{opacity: 1, y: 0},
    exit:{opacity: 0, y: -8},
    transition:{duration: '.25'}
}

const cartCardContent = [
    {title: 'Reimagined Header', body: 'I took note of every page available from Lenovo’s current global navigation and then re-sorted it by more intuitive categories.'},
    {title: 'Lifestyle Imagery', body: 'Having a big picture of a laptop might not be the most exciting landing page for a user, so I created 6 headers that connect to the life Lenovo’s products enable.'},
    {title: 'Improved Copywriting', body: 'After browsing Lenovo’s B2B offerings, I had a good understanding of the language so that I could create new content around their services. several subtotal lines throughout the cart page, customers tried using calculators to understand their true total.'},
    {title: '12 Column Grid', body: 'Introducing a flexible grid allows for 1, 2, 3, 4, and 6 columns to fit within the space and provide dynamic content.'},
    {title: 'More Scannable', body: 'The main issue with their previous product page is poor hierarchy resulting in poor scannability and lack of clear next steps.'},
    {title: 'Consistent Text', body: 'Product images often suffer from too many text styles. Limiting them can help create rhythm and readability.'},
    {title: 'Illustrated Features', body: 'Pulling out a few key features of the device and connecting them to the tangible form factor helps customers make an informed decision.'},
]

const cartScrollPositions = {
    0: { y: '0%' },
    1: { y: '-2%' },
    2: { y: '-3%' },
    3: { y: '-22%' },
    4: { y: '0%' },
    5: { y: '-20%' },
    6: { y: '-40%' },
}

function CartCallOuts(props) {
    return <div className="call-out-area">
        <AnimatePresence>
            {props.state == 0 && <motion.div key={0} {...animationProps} className="call-out-section" style={{width: '98%', height: '8%', left: '1%', top: '.8%'}}/>}
            {props.state == 1 && <motion.div key={1} {...animationProps} className="call-out-section" style={{width: '90%', height: '26%', left: '5%', top: '7%'}}/>}
            {props.state == 2 && <motion.div key={2} {...animationProps} className="call-out-section" style={{width: '35%', height: '16%', left: '5%', top: '11%'}}/>}
            {props.state == 3 && <motion.div key={3} {...animationProps} className="call-out-section" style={{width: '29%', height: '1.5%', left: '6.5%', top: '15%'}}/>}
            {props.state == 3 && <motion.div key={4} {...animationProps} className="call-out-section" style={{width: '29%', height: '1.5%', left: '36%', top: '15%'}}/>}
            {props.state == 3 && <motion.div key={5} {...animationProps} className="call-out-section" style={{width: '28%', height: '1.5%', left: '65.5%', top: '15%'}}/>}
            {props.state == 4 && <motion.div key={6} {...animationProps} className="call-out-section" style={{width: '40%', height: '24%', left: '49%', top: '16.5%'}}/>}
            {props.state == 5 && <motion.div key={7} {...animationProps} className="call-out-section" style={{width: '40%', height: '24%', left: '49%', top: '8%'}}/>}
            {props.state == 6 && <motion.div key={8} {...animationProps} className="call-out-section" style={{width: '84%', height: '24%', left: '8%', top: '2%'}}/>}
        </AnimatePresence>
    </div>
}


const cartCallOutCardVariants = {
        0: { left: '71%', top: '.5%' },
        1: { left: '71%', top: '7%' },
        2: { left: '71%', top: '13%' },
        3: { left: '71%', top: '15%' },
        4: { left: '71%', top: '16%' },
        5: { left: '71%', top: '8%' },
        6: { left: '71%', top: '2%' },
}

function CallOutCard(props) {
    const controls = useAnimationControls()

    useEffect(() => {
        controls.start(props.state + '')
    }, [props.state])

    return <motion.div 
      className="call-out-card" 
      variants={props.variants}
      initial={{ left: '71%', top: '0%' }}
      animate={controls}
    >
      <div className="title">{props.cardContent[props.state].title}</div>
      <div className="body">{props.cardContent[props.state].body}</div>
  </motion.div>
}
