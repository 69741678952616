import { useState } from "react"
import Footer from "../00-Sitewide/footer"


const info = [
    {
        name: 'Homepage',
        score: '4.9',
        image: "02-delta/07-conclusion/delta-screen-1.jpg",
        clarification: '10 participants, when asked about how useful this page would be to their current workflow'
    },
    {
        name: 'Notifications',
        score: '4.8',
        image: "02-delta/07-conclusion/delta-screen-2.jpg",
    },
    {
        name: 'Team Schedule',
        score: '5',
        image: "02-delta/07-conclusion/delta-screen-3.jpg",
    },
    {
        name: 'My Team',
        score: '5',
        image: "02-delta/07-conclusion/delta-screen-4.jpg",
    },
    {
        name: 'Employee Profile',
        score: '5.2',
        image: "02-delta/07-conclusion/delta-screen-5.jpg",
        clarification: 'two participants individually asked if they could rank this a 6 out of 5'
    },
    {
        name: 'Weather & IROPS',
        score: '3.6',
        image: "02-delta/07-conclusion/delta-screen-6.jpg",
    },
    {
        name: 'Bulletins',
        score: '4.1',
        image: "02-delta/07-conclusion/delta-screen-7.jpg",
    },
    {
        name: 'Quick Links',
        score: '4.5',
        image: "02-delta/07-conclusion/delta-screen-8.jpg",
    },
]

export default function DeltaConclusion(props) {
    return <>
        <div className="image-and-caption monospace">
            <div className="image">
                <img src={info[props.state].image}/>
            </div>
            <div className="caption">
                <div className="card">
                    <div className="title">{info[props.state].name}</div>
                    <div className="subtitle">User Testing Score:</div>
                    <div className="numbers">
                        <div className="numerator">{info[props.state].score}</div>
                        <div className="denominator">/5</div>
                    </div>
                    {info[props.state].clarification && 
                    <div className="subtitle"><span className="superscript">*</span>{info[props.state].clarification}</div>
                    }
                </div>
            </div>
        </div>
        <Footer nextProject={3}/>
    </>
}
