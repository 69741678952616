import { Link } from 'react-router-dom'

export default function Footer(props) {
    return <div className="footer display-tables monospace">
            <div className="table-card" style={{textAlign: 'center'}}>
                <div className="title-section">
                    <div className="card-title">There's much more to this project</div>
                    <div className="card-subtitle">Want to talk about it? <a target="blank" href="https://www.linkedin.com/in/dallinhiggins/">Get in touch.</a></div>
                    <div className="card-subtitle">Next Project: 
                        {props.nextProject== 1 && <Link to="/discounttire">Discount Tire</Link>}
                        {props.nextProject== 2 && <Link to="/delta">Delta Airlines</Link>}
                        {props.nextProject== 3 && <Link to="/lenovo">Lenovo</Link>}
                        {props.nextProject== 4 && <Link to="/dtinnovation">Discount Tire Innovation Lab</Link>}
                    </div>
                </div>
            </div>
        </div>
}