import './style.css'
import { Link } from 'react-router-dom'
import Experience from './Experience'
import TopBar from './00-Sitewide/topBar'

export default function Homepage() {
    return <>
        <div className="home-page">
            <div className="home-page-top-bar">
                {/* <TopBar/> */}
            </div>
       </div>
        {/* <Link to="/discounttire">
            <p>Discount Tire</p>
        </Link>
        <Link to="/delta">
            <p>Delta</p>
        </Link>
        <Link to="/lenovo">
            <p>Lenovo</p>
        </Link>
        <Link to="/dtinnovation">
            <p>Discount Tire Innovation Lab</p>
        </Link> */}
        <div className="three-d">
            <Experience/>
        </div>
    </>
}