import { useEffect, useRef, useState } from 'react'
import './style.css'
import { useMotionValueEvent, motion, useScroll, AnimatePresence } from 'framer-motion'
import { DesignSystemMolecules, DesignSystemOrganisms, DesignSystemTokens, DetailedDesign, Outcome, ProductListingPage, ShoppingCart } from './01-DiscountTire/discounttireModules'
import TopBar from './00-Sitewide/topBar'
import { DesignPrinciples, DesktopHomepage } from './03-Lenovo/lenovoModules'
import Footer from './00-Sitewide/footer'

function TableOfContentsChip(props) {
    const [active, setActive] = useState(props.active == props.sectionNum)

    const { scrollYProgress } = useScroll({
        target: props.sectionRef,
        offset: ['start center', 'end center']
    })

    const [progress, setProgress] = useState('0%')

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        setProgress(latest * 100 + "%")
        if(latest > 0 && latest < 1) {
            setActive(true)
            props.setCurrentSection(props.sectionNum)
        }
        else {
            setActive(false)
        }
    })

    return <motion.div 
      className="chip" 
      style={active ? {border: '1px solid #B08BFF', color: '#B08BFF', fontWeight: '600'} : {}}
      onClick={() => {props.sectionRef.current?.scrollIntoView({behavior: 'instant', block: "center"})}}
    >
        {props.name}
        <div className="chip-progress" style={{width: progress}}></div>
    </motion.div>
}

function ContentChip(props) {
    const active = props.state == props.id
    return <motion.div 
    className="chip" 
    style={active ? {border: '1px solid #B08BFF', color: '#B08BFF', padding: '5px 7px', fontWeight: '600'} : {}}
    onClick={() => {props.setState(props.id)}}
  >
      {props.name}
  </motion.div>
}

export default function Lenovo() {
    const [currentSection, setCurrentSection] = useState(0)

    const sectionRef1 = useRef()
    const sectionRef2 = useRef()
    const sectionRef3 = useRef()
    const sectionRef4 = useRef()
    const sectionRef5 = useRef()
    const sectionRef6 = useRef()

    const [researchActive, setResearchActive] = useState(2)
    const [finalProduct1Active, setFinalProduct1Active] = useState(0)
    const [designPivotActive, setDesignPivotActive] = useState(0)
    const [finalProduct2Active, setFinalProduct2Active ] = useState(0)
    const [outcomeActive, setOutcomeActive ] = useState(0)
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
    <div className="full-page">
        <div className="two-columns">
            <motion.div 
              className="left-column"
              initial={{x: -32, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              transition={{duration: .4}}
            >
                <TopBar nextProject={4}/>
                <div className="left-column-content">

                    <div className="mobile-image">
                        <img src='03-lenovo/99-mobile/00-hero-image.jpg'/>
                    </div>

                    {/* PROJECT SUMMARY HEADER */}
                    <div className="first-section" ref={sectionRef1}>
                        <div className="project-title">
                            Lenovo Website Redesign
                        </div>
                        <div className="paragraph">
                            <div className="title">My Role</div>
                            <div className="body">Design Lead</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Problem Statement</div>
                            <div className="body">Lenovo wants a more “modern” web experience that is more aimed toward their B2B clients.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Process & Deliverables</div>
                            <div className="body">I conducted user interviews, created a design direction, personas & journey map. Then created designs, presented to the client, got feedback, and then presented back to the client.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Outcome</div>
                            <ul className="body">
                                <li>Won bid for an $800k design phase by presenting two directions for the website.</li>
                                <li>Continued relationship which has led to even more projects with Lenovo down the road.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='03-lenovo/99-mobile/01-persona.jpg'/>
                    </div>

                    {/* RESEARCH & PROCESS */}
                    <div className="section" ref={sectionRef2}>
                        <AnimatePresence>
                            {currentSection == 2 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Research & Process
                            </div>
                            <div className="section-subtitle">
                                Let’s get some solid design direction
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Current Homepage" id={0} state={researchActive} setState={setResearchActive}/>
                                <ContentChip name="Design Principles" id={1} state={researchActive} setState={setResearchActive}/>
                                <ContentChip name="B2B Persona" id={2} state={researchActive} setState={setResearchActive}/>
                                <ContentChip name="B2C Persona" id={3} state={researchActive} setState={setResearchActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Methodology</div>
                            <div className="body">I interviewed 8 individuals who work in software procurement, and 4 individuals who have shopped for their own phone or computer recently.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">4 Principles of Design</div>
                            <div className="body">From the insights we gained during interviews, I worked on creating a 4 pillar design framework from priorities that were important to our users.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Personas</div>
                            <div className="body">I created personas for enterprise and consumer users to better compare & contrast their needs. This also allows me to have a mind frame when going through the web experience.</div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='03-lenovo/99-mobile/02-homepage.jpg'/>
                    </div>

                    {/* FIRST FINAL PRODUCT */}
                    <div className="section" ref={sectionRef3}>
                        <AnimatePresence>
                            {currentSection == 3 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Final Product <br/> Or so I thought...
                            </div>
                            <div className="section-subtitle">
                                A redesign your parents would find intuitive
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Direction</div>
                            <div className="body">I went with a fairly safe redesign: something simple & clean. Instead of focusing on product imagery, I chose to focus on the people that Lenovo serves and the lifestyle their products enable.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Homepage</div>
                            <div className="body">I kept my 4 design principles in mind as I designed a new homepage that had a few key design improvements: </div>
                            <div className="content-chip-section">
                                <ContentChip name="Reimagined Header" id={0} state={finalProduct1Active} setState={setFinalProduct1Active}/>
                                <ContentChip name="Lifestyle Imagery" id={1} state={finalProduct1Active} setState={setFinalProduct1Active}/>
                                <ContentChip name="Improved Copywriting" id={2} state={finalProduct1Active} setState={setFinalProduct1Active}/>
                                <ContentChip name="12 Column Grid" id={3} state={finalProduct1Active} setState={setFinalProduct1Active}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Product Page</div>
                            <div className="body">The new product page does a better job of prioritizing info that the customer wants to see, and mimics the successful UX of other e-commerce experiences.</div>
                            <div className="content-chip-section">
                                <ContentChip name="More Scannable" id={4} state={finalProduct1Active} setState={setFinalProduct1Active}/>
                                <ContentChip name="Consistent Text" id={5} state={finalProduct1Active} setState={setFinalProduct1Active}/>
                                <ContentChip name="Illustrated Features" id={6} state={finalProduct1Active} setState={setFinalProduct1Active}/>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='03-lenovo/99-mobile/03-blender.jpg'/>
                    </div>

                    {/* DESIGN PIVOT */}
                    <div className="section" ref={sectionRef4}>
                        <AnimatePresence>
                            {currentSection == 4 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Design Pivot
                            </div>
                            <div className="section-subtitle">
                                Getting some last minute client feedback
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Feedback" id={0} state={designPivotActive} setState={setDesignPivotActive}/>
                                <ContentChip name="Asset Creation" id={1} state={designPivotActive} setState={setDesignPivotActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Feedback</div>
                            <div className="body">“This is nice but we were hoping you guys would go bigger with all this, something more exciting, more modern”. –Client 2 days ahead of final presentation</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Concept Pivot</div>
                            <div className="body">I went back to the drawing board and created wireframes for new homepage, new e-commerce quiz, and new visual identity for the redesign.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">3D Art Direction</div>
                            <div className="body">With the client telling me to go more modern with the visuals, I chose to dive more into 3D models, writing new copy to accompany the new 3D illustration style.</div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='03-lenovo/99-mobile/04-homepage.jpg'/>
                    </div>

                    {/* SECOND FINAL PRODUCT */}
                    <div className="section" ref={sectionRef5}>
                        <AnimatePresence>
                            {currentSection == 5 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Final Product <br/>
                                For Real This Time!
                            </div>
                            <div className="section-subtitle">
                                Making something that *POPS*
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Homepage</div>
                            <div className="body">I created a homepage concept incorporating simple 3D shapes and Lenovo products, from an isometric perspective, to create something visually unique that communicates offerings well.</div>
                            <div className="content-chip-section">
                                <ContentChip name="Homepage" id={0} state={finalProduct2Active} setState={setFinalProduct2Active}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">OneLenovo Quiz</div>
                            <div className="body">An e-commerce quiz does a great job addressing our 4 design pillars we found from our research. I wanted the visual style to feel 3D like the new homepage, but also distinct by changing the perspective to first person, so the user feels “in the world” of Lenovo.</div>
                            <div className="content-chip-section">
                                <ContentChip name="OneLenovo" id={1} state={finalProduct2Active} setState={setFinalProduct2Active}/>
                                <ContentChip name="Quiz Questions" id={2} state={finalProduct2Active} setState={setFinalProduct2Active}/>
                                <ContentChip name="Results" id={3} state={finalProduct2Active} setState={setFinalProduct2Active}/>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='03-lenovo/99-mobile/05-slide.jpg'/>
                    </div>

                    {/* OUTCOME */}
                    <div className="section" ref={sectionRef6}>
                        <AnimatePresence>
                            {currentSection == 6 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Outcome
                            </div>
                            <div className="section-subtitle">
                                So... did it work?
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Approach Framework" id={0} state={outcomeActive} setState={setOutcomeActive}/>
                                <ContentChip name="Design Rationale" id={1} state={outcomeActive} setState={setOutcomeActive}/>
                                <ContentChip name="Additional Pages" id={2} state={outcomeActive} setState={setOutcomeActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Presenting</div>
                            <div className="body">I worked with IBM partners to framework for the presentation, providing visual design and storytelling for the pitch we delivered.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Result</div>
                            <div className="body">IBM won a $800k contract to carry out a discovery & design phase. The client was particularly impressed with the two directions showing both concern for scope & timeline, but also innovative outlook and big ideas.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">My Takeaway</div>
                            <div className="body">One of my favorite parts of this project was the experience principles. Distilling our research into 4 pillars and using those to inform design decisions ensured that the research findings stayed front of mind and connected back to the final products.</div>
                        </div>
                    </div>
                </div>
                <div className="mobile-only">
                    <Footer nextProject={4}/>
                </div>
            </motion.div>

            {/* LEFT COLUMN ENDS */}
            {/* RIGHT COLUMN STARTS */}

            <div className="right-column">    
                <div className="right-column-content">
                    <motion.div 
                      className="table-of-contents"
                      initial={{y: -32, opacity: 0}}
                      animate={{y: 0, opacity: 1}}
                      transition={{duration: .3}}
                    >
                        <TableOfContentsChip name="Overview" sectionNum={1} active={currentSection} sectionRef={sectionRef1} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Research" sectionNum={2} active={currentSection} sectionRef={sectionRef2} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Final Product" sectionNum={3} active={currentSection} sectionRef={sectionRef3} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Design Pivot" sectionNum={4} active={currentSection} sectionRef={sectionRef4} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Final Product" sectionNum={5} active={currentSection} sectionRef={sectionRef5} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Outcome" sectionNum={6} active={currentSection} sectionRef={sectionRef6} setCurrentSection={setCurrentSection}/>
                    </motion.div>
                    <div className="main-content">
                        {/* <div className="hero-image">
                            <img src='01-discounttire/image 1266.jpg'/>
                        </div> */}
                        <div className="section-title-box">
                            <AnimatePresence mode='wait'>
                                {currentSection == 1 && <motion.div 
                                  key={0} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Overview
                                </motion.div>}
                                {currentSection == 2 && <motion.div 
                                  key={1} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Research ::
                                    <AnimatePresence mode='wait'>
                                        {researchActive== 0 && <motion.div key={0}>
                                            Existing Homepage
                                        </motion.div>}
                                        {researchActive== 1 && <motion.div key={1}>
                                            Design Principles
                                        </motion.div>}
                                        {researchActive== 2 && <motion.div key={2}>
                                            B2B Persona
                                        </motion.div>}
                                        {researchActive== 3 && <motion.div key={3}>
                                            B2C Persona
                                        </motion.div>}
                                    </AnimatePresence>
                                </motion.div>}
                                {currentSection == 3 && <motion.div 
                                  key={2} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    (Sorta) Final Product
                                </motion.div>}
                                {currentSection == 4 && <motion.div 
                                  key={3} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Design Pivot
                                    <AnimatePresence mode='wait'>
                                    </AnimatePresence>
                                </motion.div>}
                                {currentSection == 5 && <motion.div 
                                  key={4} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Final Final Product
                                </motion.div>}
                                {currentSection == 6 && <motion.div 
                                  key={5} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Outcome
                                </motion.div>}
                            </AnimatePresence>
                        </div>

                        
                        <AnimatePresence mode='wait'>

                            {/* OVERVIEW VISUALS */}
                            {currentSection == 1 && <motion.div 
                              className="hero-image"
                              key={0}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <motion.img 
                                    src='03-lenovo/00-hero-image.jpg'
                                    initial={{scale: 1.15}}
                                    animate={{scale: 1}}
                                    transition={{duration:.25}}
                                />
                            </motion.div>}

                            {/* INITIAL RESEARCH VISUALS */}
                            {currentSection == 2 && <motion.div
                              key={1}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>

                                    {/* CURRENT HOMEPAGE */}
                                    {researchActive == 0 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={0}
                                    >
                                        <div className="image">
                                            <img src='03-lenovo/01-current-homepage.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Opportunities</div>
                                            <div className="description">The existing homepage for Lenovo doesn't do a great job of demonstrating their products or value. I led users through a typical buying process on the website, enterprise users going through the process to find a storage solution, and consumers through the process of buying a laptop.</div>
                                        </div>
                                    </motion.div>}

                                    {/* WILL BE DESIGN PRINCIPLES */}
                                    {researchActive == 1 && <motion.div
                                      className="hero-image"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={1}
                                    >
                                        <DesignPrinciples/>
                                    </motion.div>}

                                    {/* PERSONA 1 */}
                                    {researchActive == 2 && <motion.div
                                      className="hero-image"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={2}
                                    >
                                        <img src='03-lenovo/02-persona-1.jpg'/>
                                    </motion.div>}

                                    {/* PERSONA 2 */}
                                    {researchActive == 3 && <motion.div
                                      className="hero-image"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={3}
                                    >
                                        <img src='03-lenovo/02-persona-2.jpg'/>
                                    </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* FINAL PRODUCT 1 */}
                            {currentSection == 3 && <motion.div 
                            //   className="hero-image"
                              key={2}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                {/* REPLACE THIS WITH A DESKTOP SCROLLABLE MODULE*/}
                                <DesktopHomepage
                                    state={finalProduct1Active}
                                />
                            </motion.div>}

                            {/* DESIGN PIVOT */}
                            {currentSection == 4 && <motion.div 
                            //   className="hero-image"
                              key={3}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>
                                {designPivotActive == 0 && <motion.div
                                    className="hero-image"
                                    initial={{y:32, opacity: 0}}
                                    animate={{y:0, opacity: 1}}
                                    exit={{y:-32, opacity: 0}}
                                    transition={{duration: ".1"}}
                                    key={0}
                                >
                                    <div className="client-quote">
                                        "We were hoping for something more... <span className='inverse-text'>modern</span>"
                                    </div>
                                </motion.div>}
                                {designPivotActive == 1 && <motion.div
                                    className="image-and-caption"
                                    initial={{y:32, opacity: 0}}
                                    animate={{y:0, opacity: 1}}
                                    exit={{y:-32, opacity: 0}}
                                    transition={{duration: ".1"}}
                                    key={1}
                                >
                                    <div className="image">
                                        <img src='03-lenovo/04-blender.jpg'/>
                                    </div>
                                    <div className="caption">
                                        <div className="title">3D Modeling</div>
                                        <div className="description">I worked in Blender to create original 3D illustrations that could be used throughout the site.</div>
                                    </div>
                                </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* FINAL PRODUCT 2 */}
                            {currentSection == 5 && <motion.div 
                            //   className="hero-image"
                              key={4}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>
                                    {/* 3D HOMEPAGE */}
                                    {finalProduct2Active == 0 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={0}
                                    >
                                        <div className="image">
                                            <img src='03-lenovo/05-homepage.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Homepage</div>
                                            <div className="description">The homepage is meant to be simple & exciting for our client, serving as a representation of a minimal, user-friendly redesign.</div>
                                        </div>
                                    </motion.div>}

                                    {/* ONELENOVO ENTRY */}
                                    {finalProduct2Active == 1 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={1}
                                    >
                                        <div className="image">
                                            <img src='03-lenovo/05-onelenovo-1.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">OneLenovo</div>
                                            <div className="description">This is our signature feature: The One Lenovo Experience quiz. A six question quiz that results in recommended products that fit your needs.</div>
                                        </div>
                                    </motion.div>}

                                    {/* ONELENOVO QUIZ QUESTION */}
                                    {finalProduct2Active == 2 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={2}
                                    >
                                        <div className="image">
                                            <img src='03-lenovo/05-onelenovo-2.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Quiz Questions</div>
                                            <div className="description">While interviewing B2B software procurers, I asked the kinds of data points and criteria go into making their purchase decisions, and used those to guide the 6 variable questions in this experience.</div>
                                        </div>
                                    </motion.div>}

                                    {/* RESULTS SCREEN */}
                                    {finalProduct2Active == 3 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={3}
                                    >
                                        <div className="image">
                                            <img src='03-lenovo/05-onelenovo-3.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Results</div>
                                            <div className="description">A big mistake e-commerce quizzes often make is not explaining why the suggested products are suggested. A “Why it’s a great match” section gives the user transparency and confidence
                                            </div>
                                        </div>
                                    </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* OUTCOME */}
                            {currentSection == 6 && <motion.div 
                            //   className="hero-image"
                              key={5}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>
                                    {/* 3D HOMEPAGE */}
                                    {outcomeActive == 0 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={0}
                                    >
                                        <div className="image">
                                            <img src='03-lenovo/06-slide-1.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Approach Framework</div>
                                            <div className="description">When creating the presentation deck, I created slides to talk about the methodology and process behind our proposed design direction, making sure to focus on the personas we created for their target demographics.
                                            </div>
                                        </div>
                                    </motion.div>}

                                    {/* ONELENOVO ENTRY */}
                                    {outcomeActive == 1 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={1}
                                    >
                                        <div className="image">
                                            <img src='03-lenovo/06-slide-2.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Design Rationale</div>
                                            <div className="description">When creating the presentation deck, I made sure to balance the new visuals with justification and explanation behind the design changes.
                                            </div>
                                        </div>
                                    </motion.div>}

                                    {/* ONELENOVO QUIZ QUESTION */}
                                    {outcomeActive == 2 && <motion.div
                                      className="image-and-caption"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={2}
                                    >
                                        <div className="image">
                                            <img src='03-lenovo/06-slide-3.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Additional Pages</div>
                                            <div className="description">We presented both the 3D visual identity redesign, as well as sitewide UX enhancements to position IBM as both a creative and strategic partner.
                                            </div>
                                        </div>
                                    </motion.div>}
                                </AnimatePresence>
                                <Footer nextProject={4}/>
                            </motion.div>}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
}