import { useEffect, useRef, useState } from 'react'
import './style.css'
import { useMotionValueEvent, motion, useScroll, AnimatePresence } from 'framer-motion'
import { DesignSystemMolecules, DesignSystemOrganisms, DesignSystemTokens, DetailedDesign, Outcome, ProductListingPage, ShoppingCart } from './01-DiscountTire/discounttireModules'
import TopBar from './00-Sitewide/topBar'
import DeltaConclusion from './02-Delta/deltaModules'
import Footer from './00-Sitewide/footer'

function TableOfContentsChip(props) {
    const [active, setActive] = useState(props.active == props.sectionNum)

    const { scrollYProgress } = useScroll({
        target: props.sectionRef,
        offset: ['start center', 'end center']
    })

    const [progress, setProgress] = useState('0%')

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        setProgress(latest * 100 + "%")
        if(latest > 0 && latest < 1) {
            setActive(true)
            props.setCurrentSection(props.sectionNum)
        }
        else {
            setActive(false)
        }
    })

    return <motion.div 
      className="chip" 
      style={active ? {border: '1px solid #B08BFF', color: '#B08BFF', fontWeight: '600'} : {}}
      onClick={() => {props.sectionRef.current?.scrollIntoView({behavior: 'instant', block: "center"})}}
    >
        {props.name}
        <div className="chip-progress" style={{width: progress}}></div>
    </motion.div>
}

function ContentChip(props) {
    const active = props.state == props.id
    return <motion.div 
    className="chip" 
    style={active ? {border: '1px solid #B08BFF', color: '#B08BFF', padding: '5px 7px', fontWeight: '600'} : {}}
    onClick={() => {props.setState(props.id)}}
  >
      {props.name}
  </motion.div>
}

function ModalImage(props) {
    return <>
        {props.isOpen==props.id && <div className="overlay" onClick={() => props.setOpen(0)}>
            
            <motion.div 
              className="expanded-image"
              initial={{y: 12, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              transition={{duration: .15}}
            >
                <div className="close-button">
                    &#x2715;
                </div>
                <img src={props.image}/>
            </motion.div>
        </div>}
    </>
}

export default function Delta() {
    const [currentSection, setCurrentSection] = useState(0)

    const sectionRef1 = useRef()
    const sectionRef2 = useRef()
    const sectionRef3 = useRef()
    const sectionRef4 = useRef()
    const sectionRef5 = useRef()

    const [researchActive, setResearchActive] = useState(0)
    const [edtActive, setEdtActive] = useState(0)
    const [finalProductActive, setFinalProductActive] = useState(0)
    const [outcomeActive, setOutcomeActive ] = useState(0)

    // 0 is closed, 1 is journey map
    const [modalOpen, setModalOpen] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <>
    <div className="full-page">
        <ModalImage image='02-delta/02-journey-map.jpg' isOpen={modalOpen} setOpen={setModalOpen} id={1}/>
        <ModalImage image='02-delta/03-edt-1.jpg' isOpen={modalOpen} setOpen={setModalOpen} id={2}/>
        <ModalImage image='02-delta/03-edt-2.jpg' isOpen={modalOpen} setOpen={setModalOpen} id={3}/>
        <div className="two-columns">
            <motion.div 
              className="left-column"
              initial={{x: -32, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              transition={{duration: .4}}
            >
                <TopBar nextProject={3}/>
                <div className="left-column-content">

                    <div className="mobile-image">
                        <img src='02-delta/99-mobile/00-hero-image.jpg'/>
                    </div>

                    {/* PROJECT SUMMARY HEADER */}
                    <div className="first-section" ref={sectionRef1}>
                        <div className="project-title">
                            Delta Airlines Management Tool
                        </div>
                        <div className="paragraph">
                            <div className="title">My Role</div>
                            <div className="body">UX Researcher & Product Designer (Team of 3)</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Problem Statement</div>
                            <div className="body">Delta’s team managers use many outdated and unintuitive programs, some as old as 1995, to manage their direct reports.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Process & Deliverables</div>
                            <div className="body">This engagement followed a traditional UX process of interviews, co-creation workshops, personas, journey maps, leading to high fidelity, development-ready UI.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Outcome</div>
                            <div className="body">
                                This project is currently in development. So far, our user testing & feedback has shown positive results.
                            </div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='02-delta/99-mobile/01-persona.jpg'/>
                    </div>

                    {/* RESEARCH DELIVERABLES */}
                    <div className="section" ref={sectionRef2}>
                        <AnimatePresence>
                            {currentSection == 2 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Initial Research
                            </div>
                            <div className="section-subtitle">
                            Balancing 3 distinct user groups
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Persona" id={0} state={researchActive} setState={setResearchActive}/>
                                <ContentChip name="Journey Map" id={1} state={researchActive} setState={setResearchActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Interviews</div>
                            <div className="body">Our first goal was capturing the daily life of the managers at Delta, including goals and frustrations.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Three Departments</div>
                            <div className="body">The 3 departments we designed for are Airport Customer Service, Technical Operations, and Reservations. Each had unique challenges, so we interviewed managers from each department.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Outcome</div>
                            <div className="body">We created a persona and journey map for each of the three departments, so that we could better understand their needs going into the design phase.</div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='02-delta/99-mobile/02-edt.jpg'/>
                    </div>

                    {/* EDT WORKSHOPS */}
                    <div className="section" ref={sectionRef3}>
                        <AnimatePresence>
                            {currentSection == 3 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Enterprise Design Thinking Workshops
                            </div>
                            <div className="section-subtitle">
                                Making airplane engineers draw pretty little pictures
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Validating Assumptions" id={0} state={edtActive} setState={setEdtActive}/>
                                <ContentChip name="Co-Creation" id={1} state={edtActive} setState={setEdtActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Structure</div>
                            <div className="body">My goals going into the design thinking workshop were: validate our assumptions so far, find goals/pain points that may have been missed, and brainstorm solutions with the end users.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Co-Creation</div>
                            <div className="body">We brought each of the 3 departments into the design process by having them brainstorm and sketch ideas to the problems that they had identified.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Building Confidence</div>
                            <div className="body">An unexpected benefit of conducting a co-creation workshop like this is elevating the level of trust we had with the users by making them feel invested in the final product.</div>

                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='02-delta/99-mobile/03-desktop.jpg'/>
                    </div>

                    {/* FINAL RESULT */}
                    <div className="section" ref={sectionRef4}>
                        <AnimatePresence>
                            {currentSection == 4 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Final Product
                            </div>
                            <div className="section-subtitle">
                                Products for 3 very different teams
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Mobile – Customer Service" id={0} state={finalProductActive} setState={setFinalProductActive}/>
                                <ContentChip name="Tablet – TechOps" id={1} state={finalProductActive} setState={setFinalProductActive}/>
                                <ContentChip name="Desktop – Reservations" id={2} state={finalProductActive} setState={setFinalProductActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Form Factors & Touchpoints</div>
                            <div className="body">Through research, one thing we focused on was what hardware the users interact with. We found that each of the 3 departments primarily used different devices, so we targeted to their specific screen size.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Mobile – Customer Service</div>
                            <div className="body">Customer Service is the department that is at the front desk of the terminals, handling customer requests. Their managers are on the move and need a mobile form factor to stay mobile and do a good job supporting their team.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Tablet – TechOps</div>
                            <div className="body">Technical Operations are responsible for plane repairs and maintenance. Managers carry a tablet to record and report changes in maintenance.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Desktop – Reservations</div>
                            <div className="body">The reservations department sit at desks and work on widescreen monitors. So we primarily targeted widescreen desktop as their main form factor.</div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='02-delta/99-mobile/04-mobiles.jpg'/>
                    </div>

                    {/* OUTCOME */}
                    <div className="section" ref={sectionRef5}>
                        <AnimatePresence>
                            {currentSection == 5 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Outcome
                            </div>
                            <div className="section-subtitle">
                                So... did it work?
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Homepage" id={0} state={outcomeActive} setState={setOutcomeActive}/>
                                <ContentChip name="Notifications" id={1} state={outcomeActive} setState={setOutcomeActive}/>
                                <ContentChip name="Team Schedule" id={2} state={outcomeActive} setState={setOutcomeActive}/>
                                <ContentChip name="My Team" id={3} state={outcomeActive} setState={setOutcomeActive}/>
                                <ContentChip name="Employee Profile" id={4} state={outcomeActive} setState={setOutcomeActive}/>
                                <ContentChip name="Weather & IROPS" id={5} state={outcomeActive} setState={setOutcomeActive}/>
                                <ContentChip name="Bulletins" id={6} state={outcomeActive} setState={setOutcomeActive}/>
                                <ContentChip name="Quick Links" id={7} state={outcomeActive} setState={setOutcomeActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">User Testing</div>
                            <div className="body">We went through several rounds of approvals and user testing, asking end users to rate the usefulness of our features on a scale of 1–5.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Current Status</div>
                            <div className="body">This project is currently in development at Delta. The friends I made at Delta have kept me in the loop that the product has continued to test well as it has entered beta testing and should launch soon.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">My Takeaway</div>
                            <div className="body">The importance of validating assumptions and maintaining a dialogue with stakeholders, both users and business, in making something quickly that solves the actual problem and the client is happy with.</div>
                        </div>
                    </div>
                </div>
                <div className="mobile-only">
                    <Footer nextProject={3}/>
                </div>
            </motion.div>

            {/* LEFT COLUMN ENDS */}
            {/* RIGHT COLUMN STARTS */}

            <div className="right-column">    
                <div className="right-column-content">
                    <motion.div 
                      className="table-of-contents"
                      initial={{y: -32, opacity: 0}}
                      animate={{y: 0, opacity: 1}}
                      transition={{duration: .3}}
                    >
                        <TableOfContentsChip name="Overview" sectionNum={1} active={currentSection} sectionRef={sectionRef1} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Research" sectionNum={2} active={currentSection} sectionRef={sectionRef2} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="EDT Workshops" sectionNum={3} active={currentSection} sectionRef={sectionRef3} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Final Product" sectionNum={4} active={currentSection} sectionRef={sectionRef4} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Outcome" sectionNum={5} active={currentSection} sectionRef={sectionRef5} setCurrentSection={setCurrentSection}/>
                    </motion.div>
                    <div className="main-content">
                        {/* <div className="hero-image">
                            <img src='01-discounttire/image 1266.jpg'/>
                        </div> */}
                        <div className="section-title-box">
                            <AnimatePresence mode='wait'>
                                {currentSection == 1 && <motion.div 
                                  key={0} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Overview
                                </motion.div>}
                                {currentSection == 2 && <motion.div 
                                  key={1} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Research ::
                                    <AnimatePresence mode='wait'>
                                        {researchActive== 0 && <motion.div key={0}>
                                            Example Persona
                                        </motion.div>}
                                        {researchActive== 1 && <motion.div key={1}>
                                            Example Journey Map
                                        </motion.div>}
                                    </AnimatePresence>
                                </motion.div>}
                                {currentSection == 3 && <motion.div 
                                  key={2} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Enterprise Design Thinking Workshops
                                </motion.div>}
                                {currentSection == 4 && <motion.div 
                                  key={3} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Final Product ::
                                    <AnimatePresence mode='wait'>
                                        {finalProductActive == 0 && <motion.div key={0}>
                                            Mobile
                                        </motion.div>}
                                        {finalProductActive == 1 && <motion.div key={1}>
                                            Tablet
                                        </motion.div>}
                                        {finalProductActive == 2 && <motion.div key={2}>
                                            Desktop
                                        </motion.div>}
                                    </AnimatePresence>
                                </motion.div>}
                                {currentSection == 5 && <motion.div 
                                  key={4} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Outcome
                                </motion.div>}
                            </AnimatePresence>
                        </div>

                        
                        <AnimatePresence mode='wait'>

                            {/* OVERVIEW VISUALS */}
                            {currentSection == 1 && <motion.div 
                              className="hero-image"
                              key={0}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <motion.img 
                                    src='02-delta/00-hero-image.jpg'
                                    initial={{scale: 1.15}}
                                    animate={{scale: 1}}
                                    transition={{duration:.25}}
                                />
                            </motion.div>}

                            {/* INITIAL RESEARCH VISUALS */}
                            {currentSection == 2 && <motion.div
                              key={1}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>

                                    {/* PERSONAS */}
                                    {researchActive == 0 && <motion.div
                                      className="hero-image"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={0}
                                    >
                                        <img src='02-delta/01-persona.jpg'/>
                                    </motion.div>}

                                    {/* JOURNEY MAPS */}
                                    {researchActive == 1 && <motion.div
                                    //   className="hero-image"
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={1}
                                    >
                                       <div className="expandable-image" onClick={() => {setModalOpen(1)}}>
                                            <motion.img 
                                                src='02-delta/02-journey-map.jpg'
                                                initial={{scale: 1}}
                                                whileHover={{scale: 1.02}}
                                            />
                                            <div className="caption">*click to expand image</div>
                                        </div>
                                    </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* EDT WORKSHOPS */}
                            {currentSection == 3 && <motion.div 
                            //   className="hero-image"
                              key={2}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                {/* EDT AS-IS */}
                                {edtActive == 0 && <motion.div
                                    className="hero-image"
                                    initial={{y:32, opacity: 0}}
                                    animate={{y:0, opacity: 1}}
                                    exit={{y:-32, opacity: 0}}
                                    transition={{duration: ".1"}}
                                    key={0}
                                >
                                    <div className="expandable-image" onClick={() => {setModalOpen(2)}}>
                                        <motion.img 
                                            src='02-delta/03-edt-1.jpg'
                                            initial={{scale: 1}}
                                            whileHover={{scale: 1.02}}
                                        />
                                        <div className="caption">*click to expand image</div>
                                    </div>
                                </motion.div>}

                                {/* CO-CREATION */}
                                {edtActive == 1 && <motion.div
                                    className="hero-image"
                                    initial={{y:32, opacity: 0}}
                                    animate={{y:0, opacity: 1}}
                                    exit={{y:-32, opacity: 0}}
                                    transition={{duration: ".1"}}
                                    key={1}
                                >
                                    <div className="expandable-image" onClick={() => {setModalOpen(3)}}>
                                        <motion.img 
                                            src='02-delta/03-edt-2.jpg'
                                            initial={{scale: 1}}
                                            whileHover={{scale: 1.02}}
                                        />
                                        <div className="caption">*click to expand image</div>
                                    </div>
                                </motion.div>}
                            </motion.div>}

                            {/* FINAL PRODUCT */}
                            {currentSection == 4 && <motion.div 
                            //   className="hero-image"
                              key={3}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>

                                {/* MOBILE COMPS */}
                                {finalProductActive == 0 && <motion.div
                                    className="three-columns"
                                    style={{maxWidth:'90%'}}
                                    initial={{y:32, opacity: 0}}
                                    animate={{y:0, opacity: 1}}
                                    exit={{y:-32, opacity: 0}}
                                    transition={{duration: ".1"}}
                                    key={0}
                                >
                                    <div className="column">
                                        <img src='02-delta/04-mobile-1.png'/>
                                        <div className="title">Homepage</div>
                                        <div className="description">In the new experience, managers can see the content that matters most to them: team metrics, operation details, team insights, and safety bulletins.</div>
                                    </div>
                                    <div className="column">
                                        <img src='02-delta/04-mobile-2.png'/>
                                        <div className="title">My Team</div>
                                        <div className="description">Managers frequently told us current tools felt impersonal and robotic, so in addition to a streamlined team view, we added anniversary and birthday icons to better facilitate the human part of management.</div>
                                    </div>
                                    <div className="column">
                                        <img src='02-delta/04-mobile-3.png'/>
                                        <div className="title">Weather</div>
                                        <div className="description">ACS needs to know about upcoming weather pattern predictions and irregular operation stations, to better serve customers who need help.</div>
                                    </div>
                                </motion.div>}

                                {/* TABLET COMPS */}
                                {finalProductActive == 1 && <motion.div
                                    className="two-column-layout"
                                    initial={{y:32, opacity: 0}}
                                    animate={{y:0, opacity: 1}}
                                    exit={{y:-32, opacity: 0}}
                                    transition={{duration: ".1"}}
                                    key={1}
                                >
                                    <div className="column">
                                        <img src='02-delta/05-tablet-1.jpg'/>
                                        <div className="title">Homepage</div>
                                        <div className="description">While built on the same design system as the mobile form factor for ACS, we prioritized important features for TechOps. Things like hourly weather and safety metrics are brought to the forefront, and turnover notes and to do lists are unique features to TechOps that our users responded overwhelmingly positive to.</div>
                                    </div>
                                    <div className="column">
                                        <img src='02-delta/05-tablet-2.jpg'/>
                                        <div className="title">My Team</div>
                                        <div className="description">The Team Schedule page is similar to the My Team page but only shows employees working that day. Unlike other departments, TechOps Managers need to know their employees qualifications to know what jobs they can be assigned, so we incorporated those details into the schedule page.</div>
                                    </div>
                                    
                                </motion.div>}

                                {/* DESKTOP COMPS */}
                                {finalProductActive == 2 && <motion.div
                                    className="image-and-caption"
                                    initial={{y:32, opacity: 0}}
                                    animate={{y:0, opacity: 1}}
                                    exit={{y:-32, opacity: 0}}
                                    transition={{duration: ".1"}}
                                    key={2}
                                >
                                    <div className="image">
                                        <img src='02-delta/06-desktop-1.jpg'/>
                                    </div>
                                    <div className="caption">
                                        <div className="title" style={{color: 'white', fontFamily: 'Rubik, sans-serif'}}>Employee Profile</div>
                                        <div className="description">Reservations managers are generally extroverted and motivated by their relationship with their employees. They're are primarily focused on 3 factors: promoter score, first call resolution, and attendance, so we put those stats front and center.</div>
                                    </div>
                                </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* OUTCOME */}
                            {currentSection == 5 && <motion.div 
                            //   className="hero-image"
                              key={4}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <DeltaConclusion state={outcomeActive}/>
                            </motion.div>}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
}