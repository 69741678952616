import { useEffect, useRef, useState } from 'react'
import './style.css'
import { useMotionValueEvent, motion, useScroll, AnimatePresence } from 'framer-motion'
import { DesignSystemMolecules, DesignSystemOrganisms, DesignSystemTokens, DetailedDesign, Outcome, ProductListingPage, ShoppingCart } from './01-DiscountTire/discounttireModules'
import TopBar from './00-Sitewide/topBar'
import Footer from './00-Sitewide/footer'

function TableOfContentsChip(props) {
    const [active, setActive] = useState(props.active == props.sectionNum)

    const { scrollYProgress } = useScroll({
        target: props.sectionRef,
        offset: ['start center', 'end center']
    })

    const [progress, setProgress] = useState('0%')

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        setProgress(latest * 100 + "%")
        if(latest > 0 && latest < 1) {
            setActive(true)
            props.setCurrentSection(props.sectionNum)
            // console.log(props.active)
        }
        else {
            setActive(false)
        }
    })

    function clickEvent() {
        props.sectionRef.current?.scrollIntoView({behavior: 'instant', block: "center"})
        // props.setCurrentSection(props.sectionNum)
    }

    return <motion.div 
      className="chip" 
      style={active ? {border: '1px solid #B08BFF', color: '#B08BFF', fontWeight: '600'} : {}}
      onClick={() => clickEvent()}
    >
        {props.name}
        <div className="chip-progress" style={{width: progress}}></div>
    </motion.div>
}

function ContentChip(props) {
    const active = props.state == props.id
    return <motion.div 
    className="chip" 
    style={active ? {border: '1px solid #B08BFF', color: '#B08BFF', padding: '5px 7px', fontWeight: '600'} : {}}
    onClick={() => {props.setState(props.id)}}
  >
      {props.name}
  </motion.div>
}

export default function DiscountTire() {
    const [currentSection, setCurrentSection] = useState(0)

    const sectionRef1 = useRef()
    const sectionRef2 = useRef()
    const sectionRef3 = useRef()
    const sectionRef4 = useRef()
    const sectionRef5 = useRef()
    const sectionRef6 = useRef()

    const [designSystemActive, setDesignSystemActive] = useState(0)
    const [PLPActive, setPLPActive] = useState(6)
    const [cartActive, setCartActive] = useState(6)
    const [detailedDesignActive, setDetailedDesignActive ] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <>
    <div className="full-page">
        <div className="two-columns">
            <motion.div 
              className="left-column"
              initial={{x: -32, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              transition={{duration: .4}}
            >
                <TopBar nextProject={2}/>
                <div className="left-column-content">

                    {/* PROJECT SUMMARY HEADER */}
                    <div className="mobile-image">
                        <img src='01-discounttire/99-mobile/00-hero-image.jpg'/>
                    </div>
                    <div className="first-section" ref={sectionRef1}>
                        <div className="project-title">
                            Discount Tire Website Redesign
                        </div>
                        <div className="paragraph">
                            <div className="title">My Role</div>
                            <div className="body">Design Lead</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Problem Statement</div>
                            <div className="body">Discount Tire wanted visual and UX enhancements to their customer purchasing journey, without touching much back-end code or the overall flow.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Process & Deliverables</div>
                            <div className="body">I audited the current web experience to understand project scope and UX pain points, then created a design system and new shopping experience.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Outcome</div>
                            <div className="body">
                                <li className='bold'>$101 million+ annual revenue increase</li>
                                <li>13% increased checkout conversion</li>
                                <li>New, evolving design system</li>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='01-discounttire/99-mobile/01-design-system.jpg'/>
                    </div>

                    {/* DESIGN SYSTEM */}
                    <div className="section" ref={sectionRef2}>
                        <AnimatePresence>
                            {currentSection == 2 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Design System
                            </div>
                            <div className="section-subtitle">
                                Let's add some method to this madness.
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Tokens" id={0} state={designSystemActive} setState={setDesignSystemActive}/>
                                <ContentChip name="Molecules" id={1} state={designSystemActive} setState={setDesignSystemActive}/>
                                <ContentChip name="Organisms" id={2} state={designSystemActive} setState={setDesignSystemActive}/>
                                <ContentChip name="& More" id={3} state={designSystemActive} setState={setDesignSystemActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Opportunity</div>
                            <div className="body">Discount Tire had a lack of design governance & consistency, translating to a messy codebase. For example, the homepage in production had 31+ different typography styles.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Process & Deliverables</div>
                            <div className="body">Discount Tire had over a dozen products built with no design system. So rather than designing from scratch, we had to retrofit a design system to both enterprise and consumer patterns.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Final Result</div>
                            <div className="body">Traction is an evolving design system using atomic structure, that allows designers to build standardized enterprise and consumer products at scale easily.</div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='01-discounttire/99-mobile/02-plp-after.jpg'/>
                    </div>

                    {/* PRODUCT LISTING PAGE */}
                    <div className="section" ref={sectionRef3}>
                        <AnimatePresence>
                            {currentSection == 3 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Product Listing Page
                            </div>
                            <div className="section-subtitle">
                                Driving conversion through legibility
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Business Priority</div>
                            <div className="body">A high priority for Discount Tire is increasing conversion through their “Treadwell Tire Guide”, which asks questions about users’ driving habits and gives personalized product recommendations.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Business Problem</div>
                            <div className="body">Users land on this PLP page through the Treadwell quiz. Research showed that customers were confused about how these results reflected the quiz they just completed, and why these recommendations were chosen specifically for them.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Design Problems</div>
                            <div className="body">The wide scope of this project allowed me to solve business problem statements & some of the design problems issues I identified:</div>
                            <div className="content-chip-section">
                                <ContentChip name="Unintentional Spacing" id={0} state={PLPActive} setState={setPLPActive}/>
                                <ContentChip name="Floating Alignment" id={1} state={PLPActive} setState={setPLPActive}/>
                                <ContentChip name="Poor Hierarchy" id={2} state={PLPActive} setState={setPLPActive}/>
                                <ContentChip name="Inconsistent Imagery" id={3} state={PLPActive} setState={setPLPActive}/>
                                <ContentChip name="Lack of Spacing" id={4} state={PLPActive} setState={setPLPActive}/>
                                <ContentChip name="Too Many Styles" id={5} state={PLPActive} setState={setPLPActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Final Result</div>
                            <div className="body">I balanced the business problem & design problems I identified to deliver an improved experience with many benefits:</div>
                            <div className="content-chip-section">
                                <ContentChip name="Two Flows in One" id={6} state={PLPActive} setState={setPLPActive}/>
                                <ContentChip name="Easier Customization" id={7} state={PLPActive} setState={setPLPActive}/>
                                <ContentChip name="Treadwell First" id={8} state={PLPActive} setState={setPLPActive}/>
                                <ContentChip name="Reorganized Hierarchy" id={9} state={PLPActive} setState={setPLPActive}/>
                                <ContentChip name="Visualizing Product Info" id={10} state={PLPActive} setState={setPLPActive}/>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='01-discounttire/99-mobile/03-shopping-cart-2.jpg'/>
                    </div>

                    {/* SHOPPING CART */}
                    <div className="section" ref={sectionRef4}>
                        <AnimatePresence>
                            {currentSection == 4 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Shopping Cart
                            </div>
                            <div className="section-subtitle">
                                No more long, receipt-like design
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Starting Ask</div>
                            <div className="body">“I don’t know what I want it to look like, just not what it looks like now”.</div>
                            <div className="body">–Discount Tire’s VP of Web Experience</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Understanding Constraints</div>
                            <div className="body">Ideally, I would cut 50% of the copy here. There’s too many stakeholders and requirements for that to be realistic. Instead, almost all of this content will have to stay (and even more added), so the changes available to me are strictly visual, not content.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Design Problems</div>
                            <div className="body">Once again, in order to understand what can be improved, I first did a UX evaluation of what isn’t working on this page:</div>
                            <div className="content-chip-section">
                                <ContentChip name="Floating Alignment" id={0} state={cartActive} setState={setCartActive}/>
                                <ContentChip name="Numbers Overload" id={1} state={cartActive} setState={setCartActive}/>
                                <ContentChip name="Multiple Subtotals" id={2} state={cartActive} setState={setCartActive}/>
                                <ContentChip name="Add-On Ambiguity" id={3} state={cartActive} setState={setCartActive}/>
                                <ContentChip name="Poor Hierarchy" id={4} state={cartActive} setState={setCartActive}/>
                                <ContentChip name="Empty Space & Scrolling" id={5} state={cartActive} setState={setCartActive}/>
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Final Result</div>
                            <div className="body">I used a two column layout and re-laying out components to create a better web experience:</div>
                            <div className="content-chip-section">
                                <ContentChip name="More Scannable" id={6} state={cartActive} setState={setCartActive}/>
                                <ContentChip name="Progressive Disclosure" id={7} state={cartActive} setState={setCartActive}/>
                                <ContentChip name="Certificates Explained" id={8} state={cartActive} setState={setCartActive}/>
                                <ContentChip name="Components First" id={9} state={cartActive} setState={setCartActive}/>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-image">
                        <img src='01-discounttire/99-mobile/04-detailed-design-1.jpg'/>
                    </div>

                    {/* DETAILED DESIGN */}
                    <div className="section" ref={sectionRef5}>
                        <AnimatePresence>
                            {currentSection == 5 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Detailed Design
                            </div>
                            <div className="section-subtitle">
                                Why it can take two weeks to change the color of a button
                            </div>
                            <div className="content-chip-section">
                                <ContentChip name="Multiple Breakpoints" id={0} state={detailedDesignActive} setState={setDetailedDesignActive}/>
                                <ContentChip name="Design Rationale" id={1} state={detailedDesignActive} setState={setDetailedDesignActive}/>
                                {/* <ContentChip name="Component Prototypes" id={2} state={detailedDesignActive} setState={setDetailedDesignActive}/> */}
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">3 Main Frustrations</div>
                            <div className="body">There were 3 main obstacles that hurt speed to development: breakpoints, business approvals, and developer hand-off. Here’s how I addressed each:</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Multiple Breakpoints</div>
                            <div className="body">I create 8 comps for every feature I design: the widest & narrowest case for each of the 4 breakpoints we have identified. This takes the guess work out of constraints, grid, and flex for developers.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Business Approvals</div>
                            <div className="body">I create design rationale decks to present to business stakeholders that showed industry best practices, AB testing results, and UX principles, in order to increase confidence and buy-in.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Developer Hand-Off</div>
                            <div className="body">Things like interactions often got lost when going from design to development, that’s why component prototypes helped better capture functionality.</div>
                        </div>
                    </div>

                    {/* OUTCOME */}
                    <div className="section" ref={sectionRef6}>
                        <AnimatePresence>
                            {currentSection == 6 && <motion.div className="active-section-bar" initial={{width: '0%'}} animate={{width: '100%'}} exit={{width: '0%'}} />}
                        </AnimatePresence>
                        <div className="title-section">
                            <div className="section-title">
                                Outcome
                            </div>
                            <div className="section-subtitle">
                                So... did it work?
                            </div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Short Answer</div>
                            <div className="body">Yes.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Longer Answer</div>
                            <div className="body">The 2 screens I showed are small examples of a 2 year iterative project to improve the website. So far, that has included over $101 million estimated  increase in annual profit , according to Discount Tire’s own AB testing team, including a 13% increase from cart to checkout conversion from my cart redesign alone.</div>
                        </div>
                        <div className="paragraph">
                            <div className="title">Reflection</div>
                            <div className="body">The #1 thing I’ve learned through this redesign is stakeholder management. For much of this project, I’ve served as a bridge between business stakeholders, front-end developers, UX researchers, and product owners. Being able to juggle objectives & requirements from each of those groups, and reconcile them with the ultimate goal of improving the user experience of the website is what made this project successful.</div>
                        </div>
                    </div>
                </div>
                <div className="mobile-only">
                    <Footer nextProject={2}/>
                </div>
            </motion.div>

            {/* LEFT COLUMN ENDS */}
            {/* RIGHT COLUMN STARTS */}

            <div className="right-column">    
                <div className="right-column-content">
                    <motion.div 
                      className="table-of-contents"
                      initial={{y: -32, opacity: 0}}
                      animate={{y: 0, opacity: 1}}
                      transition={{duration: .3}}
                    >
                        <TableOfContentsChip name="Overview" sectionNum={1} active={currentSection} sectionRef={sectionRef1} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Design System" sectionNum={2} active={currentSection} sectionRef={sectionRef2} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="PLP" sectionNum={3} active={currentSection} sectionRef={sectionRef3} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Shopping Cart" sectionNum={4} active={currentSection} sectionRef={sectionRef4} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Detailed Design" sectionNum={5} active={currentSection} sectionRef={sectionRef5} setCurrentSection={setCurrentSection}/>
                        <TableOfContentsChip name="Outcome" sectionNum={6} active={currentSection} sectionRef={sectionRef6} setCurrentSection={setCurrentSection}/>
                    </motion.div>
                    <div className="main-content">
                        <div className="section-title-box">
                            <AnimatePresence mode='wait'>
                                {currentSection == 1 && <motion.div 
                                  key={0} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Overview
                                </motion.div>}
                                {currentSection == 2 && <motion.div 
                                  key={1} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Design System ::
                                    <AnimatePresence mode='wait'>
                                        {designSystemActive== 0 && <motion.div key={0}>
                                            Tokens
                                        </motion.div>}
                                        {designSystemActive== 1 && <motion.div key={1}>
                                            Molecules
                                        </motion.div>}
                                        {designSystemActive== 2 && <motion.div key={2}>
                                            Organisms
                                        </motion.div>}
                                        {designSystemActive== 3 && <motion.div key={2}>
                                            & More
                                        </motion.div>}
                                    </AnimatePresence>
                                </motion.div>}
                                {currentSection == 3 && <motion.div 
                                  key={2} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Product Listing Page
                                </motion.div>}
                                {currentSection == 4 && <motion.div 
                                  key={3} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Shopping Cart :: 
                                    <AnimatePresence mode='wait'>
                                        {cartActive<= 5 && <motion.div key={0}>
                                            Before
                                        </motion.div>}
                                        {cartActive>= 6 && <motion.div key={1}>
                                            Redesigned
                                        </motion.div>}
                                    </AnimatePresence>
                                </motion.div>}
                                {currentSection == 5 && <motion.div 
                                  key={4} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Detailed Design
                                </motion.div>}
                                {currentSection == 6 && <motion.div 
                                  key={5} 
                                  className="section-title"
                                  initial={{y:"100%"}}
                                  animate={{y:"0%"}}
                                  exit={{y:"-100%"}}
                                  transition={{duration: ".1"}}
                                >
                                    Outcome
                                </motion.div>}
                            </AnimatePresence>
                        </div>

                        
                        <AnimatePresence mode='wait'>

                            {/* OVERVIEW VISUALS */}
                            {currentSection == 1 && <motion.div 
                              className="hero-image"
                              key={0}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <motion.img 
                                    src='01-discounttire/00-hero-image.jpg'
                                    initial={{scale: 1.15}}
                                    animate={{scale: 1}}
                                    transition={{duration:.25}}
                                />
                            </motion.div>}

                            {/* DESIGN SYSTEM VISUALS */}
                            {currentSection == 2 && <motion.div
                              key={1}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>

                                    {/* TOKENS */}
                                    {designSystemActive == 0 && <motion.div
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={0}
                                    >
                                        <DesignSystemTokens/>
                                    </motion.div>}

                                    {/* MOLECULES */}
                                    {designSystemActive == 1 && <motion.div
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={1}
                                    >
                                        <DesignSystemMolecules/>
                                    </motion.div>}

                                    {/* ORGANISMS */}
                                    {designSystemActive == 2 && <motion.div
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={2}
                                    >
                                        <DesignSystemOrganisms/>
                                    </motion.div>}
                                    {/* ORGANISMS */}
                                    {designSystemActive == 3 && <motion.div
                                    className='image-and-caption'
                                      initial={{y:32, opacity: 0}}
                                      animate={{y:0, opacity: 1}}
                                      exit={{y:-32, opacity: 0}}
                                      transition={{duration: ".1"}}
                                      key={3}
                                    >
                                        <div className="image">
                                            <img src='01-discounttire/02-design-system.jpg'/>
                                        </div>
                                        <div className="caption">
                                            <div className="title">Full System</div>
                                            <div className="description">There's plenty more to show off, including how we used Storybook and Supernova to maintain code parity with the Figma system. Feel free to reach out to talk more about how the design system works.</div>
                                        </div>
                                    </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* PLP PAGE */}
                            {currentSection == 3 && <motion.div 
                            //   className="hero-image"
                              key={2}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <ProductListingPage state={PLPActive}/>
                            </motion.div>}

                            {/* SHOPPING CART */}
                            {currentSection == 4 && <motion.div 
                            //   className="hero-image"
                              key={3}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <ShoppingCart state={cartActive}/>
                            </motion.div>}

                            {/* Detailed Design*/}
                            {currentSection == 5 && <motion.div 
                            //   className="hero-image"
                              key={4}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <AnimatePresence mode='wait'>
                                    {/* TOKENS */}
                                    {detailedDesignActive == 0 && <motion.div
                                        className='hero-image'
                                        initial={{y:32, opacity: 0}}
                                        animate={{y:0, opacity: 1}}
                                        exit={{y:-32, opacity: 0}}
                                        transition={{duration: ".1"}}
                                        key={0}
                                    >
                                        <img src='01-discounttire/05-detailed-design-1.jpg'/>
                                    </motion.div>}

                                    {/* MOLECULES */}
                                    {detailedDesignActive == 1 && <motion.div
                                        className='hero-image'
                                        initial={{y:32, opacity: 0}}
                                        animate={{y:0, opacity: 1}}
                                        exit={{y:-32, opacity: 0}}
                                        transition={{duration: ".1"}}
                                        key={1}
                                    >
                                        <img src='01-discounttire/05-detailed-design-2.jpg'/>
                                    </motion.div>}
                                </AnimatePresence>
                            </motion.div>}

                            {/* SHOPPING CART */}
                            {currentSection == 6 && <motion.div 
                            //   className="hero-image"
                              key={5}
                              initial={{y:32, opacity: 0}}
                              animate={{y:0, opacity: 1}}
                              exit={{y:-32, opacity: 0}}
                              transition={{duration: ".1"}}
                            >
                                <Outcome/>
                            </motion.div>}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
}